import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, UncontrolledTooltip } from 'reactstrap';
import { useIntl } from 'react-intl';
import axios from 'axios';
import produce from 'immer';

import { stripHtml } from 'smv-helpers';
import { dataPrepActions } from 'smv-redux';

import { validateWeightDefinition } from '../helpers/validateWeightDefinition';
import { DesiredDistributionInput, handleCreateDefinitionInputTargetValues } from './DesiredDistributionInput';

export const WeightDefinitionTable = ({ weightId, definition, sources, readOnly }) => {
  const intl = useIntl();
  const surveyId = useSelector(state => state.survey.id);
  const selectedLocale = useSelector(
    state => state.projectSettings?.[surveyId]?.selectedLocale ?? state.survey.locales.main
  );
  const translations = useSelector(state => state.survey.translations);

  const [tableData, setTableData] = useState(null);
  const [showInputWarning, setShowInputWarning] = useState(null);

  // source
  const source = useMemo(() => sources[definition.sourceId], [definition.sourceId, sources]);
  const sourceType = useMemo(() => definition.sourceType, [definition.sourceType]);

  const sourceChoice = useMemo(() => getSourceChoice(definition.choiceId, definition.sourceType, sources, source), [
    definition.choiceId,
    definition.sourceType,
    source,
    sources
  ]);
  const sourceScale = useMemo(() => (definition.scaleId ? source?.config?.scales?.list?.[definition?.scaleId] : null), [
    definition.scaleId,
    source?.config?.scales?.list
  ]);

  // subgroups
  const isInSubgroups = useMemo(() => definition.inSubgroups, [definition.inSubgroups]);
  const subgroupSource = useMemo(() => sources[definition.subgroupSourceId], [sources, definition.subgroupSourceId]);
  const subgroupSourceType = useMemo(() => definition.subgroupSourceType, [definition.subgroupSourceType]);
  const subgroupChoice = useMemo(
    () => getSourceChoice(definition?.subgroupChoiceId, definition?.subgroupSourceType, sources, subgroupSource),
    [definition?.subgroupSourceType, definition?.subgroupChoiceId, sources, subgroupSource]
  );

  const isValidDefinition = useMemo(() => {
    return validateWeightDefinition(definition, sources);
  }, [definition, sources]);

  const loadResultData = useCallback(() => {
    if (isValidDefinition && definition.id) {
      axios
        .get(
          `/surveys/${surveyId}/data-preps/weights/${weightId}/definitions/${definition.id}?locale=${selectedLocale}`
        )
        .then(res => {
          setTableData(res.data.values);
          return res;
        })
        .catch(error => {
          setTableData(null);
          console.log(error);
        });
    }
  }, [isValidDefinition, surveyId, weightId, selectedLocale, definition?.id]);

  const handleExclude = (itemId, type, totalValue = null) => {
    const _totalValue = (parseFloat(totalValue) * 100).toFixed(2);
    const _definition = produce(definition, draftState => {
      switch (type) {
        case 'row':
          if (draftState.exclude?.rowChoiceIds?.includes(itemId)) {
            const tmpDefinition = handleCreateDefinitionInputTargetValues(
              0,
              itemId,
              subgroupChoice?.id,
              subgroupSource,
              definition
            );

            draftState.inputTargetValues = { ...tmpDefinition.inputTargetValues };

            if (!draftState.exclude) {
              draftState.exclude = {
                rowChoiceIds: [],
                headChoiceIds: []
              };
            }
            draftState.exclude.rowChoiceIds = draftState.exclude.rowChoiceIds.filter(item => item !== itemId);
          } else {
            const tmpDefinition = handleCreateDefinitionInputTargetValues(
              _totalValue,
              itemId,
              subgroupChoice?.id,
              subgroupSource,
              definition
            );

            draftState.inputTargetValues = { ...tmpDefinition.inputTargetValues };

            if (!draftState.exclude) {
              draftState.exclude = {
                rowChoiceIds: [],
                headChoiceIds: []
              };
            }
            if (!draftState.exclude.rowChoiceIds) {
              draftState.exclude.rowChoiceIds = [];
            }
            draftState.exclude.rowChoiceIds = [...draftState.exclude.rowChoiceIds, itemId];
          }
          break;
        case 'head':
          if (draftState.exclude?.headChoiceIds?.includes(itemId)) {
            draftState.exclude.headChoiceIds = draftState.exclude.headChoiceIds.filter(item => item !== itemId);
          } else {
            if (!draftState.exclude) {
              draftState.exclude = {
                rowChoiceIds: [],
                headChoiceIds: []
              };
            }
            if (!draftState.exclude.headChoiceIds) {
              draftState.exclude.headChoiceIds = [];
            }
            draftState.exclude.headChoiceIds = [...draftState.exclude.headChoiceIds, itemId];
          }
          break;
        default:
          break;
      }
    });
    dataPrepActions.updateWeightDefinition(surveyId, weightId, definition.id, _definition);
  };

  const handleDistributeEvenly = useCallback(() => {
    const inputTargetValues = { ...definition.inputTargetValues } || {};
    const sourceItems = source?.config?.choices?.order ?? source?.values ?? source?.order;
    const excludedItems = definition.exclude?.rowChoiceIds ?? [];
    const remainingItems = sourceItems.filter(item => !excludedItems.includes(item));

    let sumOfExcludedValues = 0;
    excludedItems.forEach(item => {
      sumOfExcludedValues += Object.values(inputTargetValues[item] ?? {})[0];
    });

    const remainingValue = 1 - sumOfExcludedValues;
    const remainingValueDistribution = remainingValue / remainingItems.length;
    const normalizedValue = parseFloat(Math.min(remainingValueDistribution * 100, 100) / 100).toFixed(4);
    const rest = remainingValue - normalizedValue * remainingItems.length;
    const normalizedRest = parseFloat(Math.min(rest * 100, 100) / 100).toFixed(4);

    const updateInputTargetValues = (draftState, item, key, value) => {
      const _item = typeof item === 'string' ? item : item.id ?? null;

      if (!draftState.inputTargetValues) {
        draftState.inputTargetValues = {};
      }
      if (_item) {
        if (!draftState.inputTargetValues[_item]) {
          draftState.inputTargetValues[_item] = {};
        }
        draftState.inputTargetValues[_item][key] = value;
      }
    };

    const updateSubgroupTargetValues = (draftState, item, source, keyExtractor, value) => {
      source.forEach(key => updateInputTargetValues(draftState, item, keyExtractor(key), value));
    };

    const _definition = produce(definition, draftState => {
      remainingItems.forEach((item, i) => {
        let _value = normalizedValue;
        if (normalizedRest && i === remainingItems.length - 1) {
          _value = parseFloat(_value) + parseFloat(normalizedRest);
        }
        if (!definition.inSubgroups) {
          updateInputTargetValues(draftState, item, '__total__', _value);
        } else if (subgroupChoice?.id) {
          updateInputTargetValues(draftState, item, subgroupChoice?.id, _value);
        } else {
          const { subgroupSourceType } = definition;

          switch (subgroupSourceType) {
            case 'question':
              updateSubgroupTargetValues(draftState, item, subgroupSource.config.choices.order, cid => cid, _value);
              break;
            case 'calcvar':
              updateSubgroupTargetValues(draftState, item, subgroupSource.values, val => val.id, _value);
              break;
            case 'sysvar':
              updateSubgroupTargetValues(draftState, item, subgroupSource.order, cid => cid, _value);
              break;
            default:
              break;
          }
        }
      });
    });

    dataPrepActions.updateWeightDefinition(surveyId, weightId, definition.id, _definition);
  }, [source, surveyId, weightId, definition, subgroupChoice?.id, subgroupSource]);

  // loads table data for selected locale
  useEffect(() => {
    loadResultData();
    setShowInputWarning(false);
    // reload result data when any of the following properties change:
  }, [
    loadResultData,
    source,
    sourceChoice,
    sourceScale,
    subgroupSource,
    subgroupChoice,
    definition.staticTableFilters
  ]);

  // create rows
  const rows = [];
  let tableHead00 = '';
  switch (sourceType) {
    case 'question':
      // regular questions
      if (source && !['multiple_vector', 'multiple_matrix', 'single_matrix'].includes(source.config?.qtype)) {
        source.config.choices.order.forEach(choiceId => {
          const choice = getQuestionChoice(sources, source, choiceId);
          rows.push(choice);
        });
        tableHead00 = source.title;
      }
      // single matrix questions
      if (source && sourceChoice && ['single_matrix'].includes(source.config?.qtype)) {
        source.config.scales.order.forEach(scaleId => {
          const scale = source.config.scales.list[scaleId];
          rows.push(scale);
        });
        tableHead00 =
          source.title + ' - ' + stripHtml(translations[sourceChoice.config.translations.label][selectedLocale]) ?? '';
      }
      // multi vector and multi matrix questions
      if (source && sourceChoice && isSourceTypeMultiple(sourceType, source)) {
        // 2x because we need the choice for quoted and not quoted
        rows.push(sourceChoice);
        rows.push(sourceChoice);
        tableHead00 =
          source.title + ' - ' + stripHtml(translations[sourceChoice.config.translations.label][selectedLocale]) ?? '';
        if (source.config.qtype === 'multiple_matrix' && sourceScale) {
          tableHead00 += ' - ' + stripHtml(translations[sourceScale.config.translations.label][selectedLocale]);
        }
      }
      break;

    case 'calcvar':
      if (source.multiple) {
        tableHead00 = sourceChoice?.label;
        // 2x for quoted / not quoted
        rows.push(sourceChoice);
        rows.push(sourceChoice);
      } else {
        tableHead00 = source.label;
        source.values.forEach(el => {
          rows.push(el);
        });
      }
      break;

    case 'sysvar':
      tableHead00 = source.label;
      source.order.forEach(el => {
        const variable = source.list[el];
        rows.push(variable);
      });
      break;

    default:
      break;
  }

  const head = [
    <th key={'th_00'}>{tableHead00}</th>,
    <th key={'th_measured_dist'} className={isInSubgroups ? 'cell--excluded' : ''}>
      {intl.formatMessage({ id: 'smoove.page.data.weight.weight-definition-table.measured-distribution-total' })}
    </th>
  ];

  // create table heads for subgroups
  if (isInSubgroups && subgroupSource) {
    switch (subgroupSourceType) {
      case 'question':
        if (['single_vector'].includes(subgroupSource.config.qtype)) {
          subgroupSource.config.choices.order.forEach(choiceId => {
            const choice = subgroupSource.config.choices.list[choiceId];
            head.push(
              <th
                key={`${subgroupSource.id}_${choiceId}`}
                className={`${definition.exclude?.headChoiceIds?.includes(choiceId) ? 'cell--excluded' : ''}`}
              >
                <ExcludeIconButton toggleExclude={() => handleExclude(choiceId, 'head')} />
                {stripHtml(translations[choice.config.translations.label][selectedLocale])}
              </th>
            );
          });
        }
        if (['multiple_vector'].includes(subgroupSource.config.qtype) && definition.subgroupChoiceId) {
          head.push(
            <th key={`${subgroupSource.id}_quoted`}>
              {stripHtml(translations[subgroupChoice.config.translations.label][selectedLocale])} - quoted
            </th>
          );
          head.push(
            <th key={`${subgroupSource.id}_not_quoted`} className='cell--excluded'>
              {stripHtml(translations[subgroupChoice.config.translations.label][selectedLocale])} - not quoted
            </th>
          );
        }
        if (['single_matrix'].includes(subgroupSource.config.qtype) && definition.subgroupChoiceId) {
          subgroupSource.config.scales.order.forEach(scaleId => {
            const scale = subgroupSource.config.scales.list[scaleId];
            head.push(
              <th
                key={`${subgroupSource.id}_${scaleId}`}
                className={`${definition.exclude?.headChoiceIds?.includes(scaleId) ? 'cell--excluded' : ''}`}
              >
                <ExcludeIconButton toggleExclude={() => handleExclude(scaleId, 'head')} />
                {stripHtml(translations[subgroupChoice.config.translations.label][selectedLocale])} -{' '}
                {stripHtml(translations[scale.config.translations.label][selectedLocale])}
              </th>
            );
          });
        }
        break;

      case 'calcvar':
        if (definition.subgroupChoiceId && subgroupSource.multiple) {
          head.push(<th key={`${subgroupSource.id}_quoted`}>{subgroupChoice.label} - quoted</th>);
          head.push(
            <th key={`${subgroupSource.id}_not_quoted`} className='cell--excluded'>
              {subgroupChoice.label} - not quoted
            </th>
          );
        } else {
          subgroupSource.values.forEach(el => {
            head.push(
              <th
                key={`${el.id}`}
                className={`${definition.exclude?.headChoiceIds?.includes(el.id) ? 'cell--excluded' : ''}`}
              >
                <ExcludeIconButton toggleExclude={() => handleExclude(el.id, 'head')} />
                {el.label}
              </th>
            );
          });
        }
        break;

      case 'sysvar':
        subgroupSource.order.forEach(el => {
          const variable = subgroupSource.list[el];
          head.push(
            <th
              key={`${variable.id}`}
              className={`${definition.exclude?.headChoiceIds?.includes(el) ? 'cell--excluded' : ''}`}
            >
              <ExcludeIconButton toggleExclude={() => handleExclude(el, 'head')} />
              {variable.label}
            </th>
          );
        });
        break;

      default:
        break;
    }
  }

  if (definition.mode === 'manual_align') {
    head.push(
      <th
        key={'th_desired_dist'}
        style={{
          width: '220px',
          wordWrap: 'break-word',
          textAlign: 'right'
        }}
      >
        <div className='d-flex align-items-center justify-content-end'>
          <span className='mr-2'>
            {intl.formatMessage({ id: 'smoove.page.data.weight.weight-definition-table.desired-distribution' })}
          </span>
          {source && !isSourceTypeMultiple(sourceType, source) && (
            <>
              <div className='pointer' id={'tt_distribute_even'} onClick={handleDistributeEvenly}>
                <i className='far fa-objects-align-right pointer' />
              </div>
              <UncontrolledTooltip target={`tt_distribute_even`}>Distribute evenly</UncontrolledTooltip>
            </>
          )}
        </div>
      </th>
    );
  }

  let colCount = 0;

  const renderColsForRow = (rowElement, i) => {
    const cols = [];
    if (!tableData) return;

    const _rowId = getRowId(sourceType, source, rowElement, definition.choiceId, sourceScale);
    let totalResult = null;

    const addResultColumn = (_result, x = _rowId, y = i, inverted = false, showAsHidden = false) => {
      const keySuffix = inverted ? 'not_quoted' : 'quoted';
      const resultValue = inverted ? toPercent(1 - _result) : toPercent(_result);
      const isExcluded =
        definition.exclude?.rowChoiceIds?.includes(x) ||
        definition.exclude?.headChoiceIds?.includes(y) ||
        inverted ||
        showAsHidden;

      cols.push(
        <td key={`${x}_${y}_${keySuffix}`} className={`${isExcluded ? 'cell--excluded' : ''}`}>
          {resultValue}
        </td>
      );
    };

    // add total columns
    if (sourceType === 'question') {
      // calculate total result
      if (['multiple_vector'].includes(source.config?.qtype)) {
        totalResult = tableData.row1[_rowId]?.__total__?.__total__?.smvrslt_perc;
      } else if (['multiple_matrix'].includes(source.config?.qtype)) {
        totalResult = tableData.row1[sourceScale.id]?.__total__?.__total__?.smvrslt_perc;
      } else {
        totalResult = tableData.row1[_rowId]?.__total__?.__total__?.smvrslt_perc;
      }
      // render label in first column
      if (isSourceTypeMultiple(sourceType, source)) {
        cols.push(
          <td key={`${_rowId}_${i}`} className={i === 1 ? 'cell--excluded' : ''}>
            {i === 1 && 'not '} quoted
          </td>
        );
      } else {
        cols.push(
          <td
            key={`${_rowId}_${i}`}
            className={`${definition.exclude?.rowChoiceIds?.includes(_rowId) ? 'cell--excluded' : ''}`}
          >
            <ExcludeIconButton toggleExclude={() => handleExclude(_rowId, 'row', totalResult)} />
            {stripHtml(translations[rowElement.config.translations.label][selectedLocale])}
          </td>
        );
      }
      // render total values
      if (['multiple_vector'].includes(source.config?.qtype)) {
        addResultColumn(totalResult, _rowId, i, i === 1, isInSubgroups);
      } else if (['multiple_matrix'].includes(source.config?.qtype)) {
        addResultColumn(totalResult, sourceScale.id, i, i === 1, true);
      } else {
        addResultColumn(totalResult, _rowId, i, false, isInSubgroups);
      }
    }
    if (sourceType === 'calcvar') {
      totalResult = tableData.row1[_rowId]?.__total__?.__total__?.smvrslt_perc;
      if (source.multiple) {
        cols.push(<td key={`${_rowId}_${i}`}>{i === 1 && 'not '} quoted</td>);
        addResultColumn(totalResult, _rowId, i, i === 1, isInSubgroups);
      } else {
        cols.push(
          <td
            key={`${_rowId}_${i}`}
            className={`${definition.exclude?.rowChoiceIds?.includes(_rowId) ? 'cell--excluded' : ''}`}
          >
            <ExcludeIconButton toggleExclude={() => handleExclude(_rowId, 'row', totalResult)} />
            {rowElement.label}
          </td>
        );
        addResultColumn(totalResult, _rowId, i, false, isInSubgroups);
      }
    }
    if (sourceType === 'sysvar') {
      totalResult = tableData.row1[_rowId]?.__total__?.__total__?.smvrslt_perc;
      cols.push(
        <td
          key={`${_rowId}_${i}`}
          className={`${definition.exclude?.rowChoiceIds?.includes(_rowId) ? 'cell--excluded' : ''}`}
        >
          <ExcludeIconButton toggleExclude={() => handleExclude(_rowId, 'row', totalResult)} />
          {rowElement.label}
        </td>
      );
      addResultColumn(totalResult, _rowId, i, false, isInSubgroups);
    }
    // add subgroup columns
    if (isInSubgroups && subgroupSource) {
      if (subgroupSourceType === 'question') {
        if (['single_vector'].includes(subgroupSource.config.qtype)) {
          subgroupSource.config.choices.order.forEach(subgroupChoice => {
            const _result = tableData.row1?.[_rowId]?.['head1']?.[subgroupChoice]?.smvrslt_perc;
            if (isSourceTypeMultiple(sourceType, source)) {
              addResultColumn(_result, _rowId, subgroupChoice, i === 1);
            } else {
              addResultColumn(_result, _rowId, subgroupChoice, false);
            }
          });
        }
        if (['multiple_vector'].includes(subgroupSource.config.qtype) && definition.subgroupChoiceId) {
          const _result = tableData.row1?.[_rowId]?.['head1']?.[definition.subgroupChoiceId]?.smvrslt_perc;
          if (isSourceTypeMultiple(sourceType, source)) {
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, i === 1);
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, i === 0, true);
          } else {
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, false);
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, true);
          }
        }
      }
      if (subgroupSourceType === 'calcvar') {
        const _result = tableData.row1?.[_rowId]?.['head1']?.[definition.subgroupChoiceId]?.smvrslt_perc;
        if (subgroupSource.multiple) {
          if (isSourceTypeMultiple(sourceType, source)) {
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, i === 1);
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, i === 0, true);
          } else {
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, false);
            addResultColumn(_result, _rowId, definition.subgroupChoiceId, true);
          }
        } else {
          subgroupSource.values.forEach(value => {
            const _result = tableData.row1?.[_rowId]?.['head1']?.[value.id]?.smvrslt_perc;
            if (isSourceTypeMultiple(sourceType, source)) {
              addResultColumn(_result, _rowId, value.id, i === 1);
            } else {
              addResultColumn(_result, _rowId, value.id, false);
            }
          });
        }
      }
      if (subgroupSourceType === 'sysvar') {
        subgroupSource.order.forEach(value => {
          const variable = subgroupSource.list[value];
          const _variableId = variable.locale ?? variable.id;
          const _result = tableData.row1?.[_rowId]?.['head1']?.[_variableId]?.smvrslt_perc;
          if (isSourceTypeMultiple(sourceType, source)) {
            addResultColumn(_result, _rowId, _variableId, i === 1);
          } else {
            addResultColumn(_result, _rowId, _variableId, false);
          }
        });
      }
    }
    // add input column
    definition.mode === 'manual_align' &&
      cols.push(
        <td key={'desired'} style={{ minWidth: '120px', textAlign: 'right' }}>
          <DesiredDistributionInput
            weightId={weightId}
            definition={definition}
            index={i}
            choiceId={_rowId}
            isMultiple={isSourceTypeMultiple(sourceType, source)}
            readOnly={readOnly}
            subgroupSource={subgroupSource}
            subgroupChoiceId={subgroupChoice?.id}
            setShowInputWarning={setShowInputWarning}
            totalResult={totalResult}
          />
        </td>
      );

    colCount = cols.length ?? 0;
    return cols;
  };

  if (!isValidDefinition)
    return (
      <div className='data-weight-definition-table mt-2'>
        <Table>
          <thead>
            <tr>
              <th>
                {intl.formatMessage({ id: 'smoove.page.data.weight.weight-definition-table.invalid-definition' })}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {intl.formatMessage({
                  id: 'smoove.page.data.weight.weight-definition-table.invalid-definition-info-text'
                })}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );

  return (
    <div className='data-weight-definition-table mt-2'>
      <Table>
        <thead>
          <tr>{head}</tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {
            return <tr key={`${i}_${row.id ?? ''}`}>{renderColsForRow(row, i)}</tr>;
          })}
          {isValidDefinition && definition?.mode === 'manual_align' && showInputWarning && (
            <tr>
              <td colSpan={colCount - 1}></td>
              <td className='text-warning text-right'>
                {showInputWarning ? (
                  <i>{intl.formatMessage({ id: 'smoove.page.data.weight.weight-definition-table.sum-not-100' })}</i>
                ) : (
                  ''
                )}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

const getQuestionChoice = (sources, source, choiceId) => {
  if (!sources || !source || !choiceId) return null;

  const choice = source.config.choices.list[choiceId];
  if (choice) {
    return choice;
  }

  const importSourceId = source.config.choices.import?.source;
  const importSource = importSourceId ? sources[importSourceId] : null;

  return importSource?.config.choices.list[choiceId] ?? null;
};

const getSourceChoice = (choiceId, sourceType, sources, source) => {
  if (choiceId && sourceType === 'question') {
    return getQuestionChoice(sources, source, choiceId);
  }
  if (choiceId && sourceType === 'calcvar') {
    return source.values.find(el => el.id === choiceId);
  }
  return null;
};

const getRowId = (sourceType, source, rowElement, choiceId, sourceScale) => {
  if (sourceType === 'question') {
    return source.config.qtype === 'multiple_matrix' ? sourceScale.id : rowElement.id;
  } else if (sourceType === 'sysvar') {
    return rowElement.locale ?? rowElement.id;
  } else {
    return choiceId ?? rowElement.id;
  }
};

const isSourceTypeMultiple = (sourceType, source) => {
  return (
    (sourceType === 'question' && ['multiple_matrix', 'multiple_vector'].includes(source.config.qtype)) ||
    (sourceType === 'calcvar' && source.multiple)
  );
};

const toPercent = value => {
  if (value === null || value === undefined) {
    return '-';
  }
  const percentValue = (value * 100).toFixed(2);
  return `${percentValue}%`;
};

const ExcludeIconButton = ({ toggleExclude }) => {
  return <i className={`fal fa-eye mr-1 pointer`} onClick={toggleExclude} />;
};
