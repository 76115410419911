import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { dataPrepActions } from 'smv-redux/actions';

import { PerformCalculationsButton } from '../PerformCalculationsButton';
import { Calculation } from '.';

export const DataCalculation = () => {
  const surveyid = useSelector(state => state.survey.id);
  const surveyData = useSelector(state => state.survey?.data);
  const isRunningCalculations = useSelector(state => state.survey?.isRunningCalculations);

  const calculationHandler = useMemo(() => {
    return {
      addCalculationValue: (calculationId, data) => {
        dataPrepActions.addCalculationValue(surveyid, calculationId, data);
      },
      updateCalculationValue: (calculationId, valueId, data) => {
        dataPrepActions.updateCalculationValue(surveyid, calculationId, valueId, data);
      },
      deleteCalculationValue: (calculationId, valueId) => {
        dataPrepActions.deleteCalculationValue(surveyid, calculationId, valueId);
      },
      addCalculation: () => {
        dataPrepActions.addCalculation(surveyid);
      },
      deleteCalculation: id => {
        dataPrepActions.deleteCalculation(surveyid, id);
      },
      updateCalculation: (id, updatedCalculation) => {
        return new Promise((resolve, reject) => {
          dataPrepActions.updateCalculation(surveyid, id, updatedCalculation).catch(error => {
            reject(error);
          });
        });
      }
    };
  }, [surveyid]);

  const hasCalculations = (surveyData ?? {})?.calculations?.length > 0;

  return (
    <>
      <Row>
        <Col className='my-4 d-flex align-items-center justify-content-between'>
          <Button onClick={() => calculationHandler.addCalculation()} color='primary' disabled={isRunningCalculations}>
            <FormattedMessage id={'smoove.page.data.calculation.add-calculation'} defaultMessage={'Add calculation'} />
          </Button>
          {hasCalculations && (
            <div>
              <PerformCalculationsButton />
            </div>
          )}
        </Col>
      </Row>
      {hasCalculations && (
        <Row>
          <Col className='my-2'>
            {surveyData.calculations.map(calculation => (
              <Calculation key={calculation.id} calculation={calculation} calculationHandler={calculationHandler} />
            ))}
          </Col>
        </Row>
      )}
      {!hasCalculations && (
        <Row>
          <Col className='mb-2'>
            <i>
              <FormattedMessage
                id={'smoove.page.data.calculation.no-calculations-defined'}
                defaultMessage={'No calculations defined'}
              />
            </i>
          </Col>
        </Row>
      )}
    </>
  );
};
