import React from 'react';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import { useSubelementPropertyTranslation } from 'smv-helpers';

export const ResultTableChartHeadColumnHeader = ({
  column,
  config,
  colSpan = 1,
  metric = 'smvrslt_perc',
  width = undefined
}) => {
  const intl = useIntl();
  const { showCodeValuesHead = false } = config;

  const subelementPropertyTranslation = useSubelementPropertyTranslation(column, null, {
    showCodeValue: showCodeValuesHead,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const label =
    metric === 'smvrslt_mean'
      ? intl.formatMessage({
          id: `smoove.common.result.aggregation.types.mean`,
          defaultMessage: 'mean value'
        })
      : subelementPropertyTranslation;
  const widthCombined = width && !isNaN(width) ? (colSpan === 1 ? width : width * colSpan) : undefined;

  return (
    <th
      style={widthCombined !== undefined ? { width: `${widthCombined}px` } : {}}
      colSpan={colSpan}
      className={classNames('head__column')}
    >
      <span title={label} className={'head__column_content'}>
        <span className='pr-1'>{label}</span>
      </span>
    </th>
  );
};
