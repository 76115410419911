import React, { useMemo } from 'react';
import { Row, Col } from 'reactstrap';
import { isArray } from 'lodash';
import produce from 'immer';
import { customAlphabet } from 'nanoid';

import { Metrics } from '../../setting-components';

const metrics = [
  {
    prop: 'calcFrequencies',
    label: 'Frequencies',
    default: true
  },
  {
    prop: 'calcMeanValue',
    label: 'Mean Value',
    default: false
  }
];

export const SingleInputNumberSettings = props => {
  const { rowid, internalRowConfig } = props;

  const internalHandler = useMemo(() => {
    return {
      setInternalConfig: props.internalHandler.setInternalRowConfig,
      setCheckboxValue: e => {
        const { name, checked } = e.target;

        props.internalHandler.setInternalRowConfig(state =>
          produce(state, draftState => {
            if (!draftState || isArray(draftState)) draftState = {};

            if (name === 'calcMeanValue') {
              if (checked) {
                const meanValueId = customAlphabet('1234567890abcdef', 24)();
                draftState[name] = { id: meanValueId };
              } else {
                draftState[name] = undefined;
              }
            } else {
              draftState[name] = checked;
            }

            if (metrics.map(metric => !!draftState?.[metric.prop]).filter(Boolean).length === 0) {
              draftState[metrics[0].prop] = true;
            }
          })
        );
      },
      setNumericValue: e => {
        const { name, value } = e.target;
        console.log(name);
        const _value = parseFloat(value);
        props.internalHandler.setInternalRowConfig(state =>
          produce(state, draftState => {
            if (!draftState || isArray(draftState)) draftState = {};
            draftState[name] = _value;
          })
        );
      }
    };
  }, [props.internalHandler]);

  return (
    <>
      <Row className='mb-3'>
        <Col className={'d-flex flex-column'}>
          <Metrics rowid={rowid} metrics={metrics} handler={internalHandler} config={internalRowConfig} />
        </Col>
      </Row>
      {/*<Row className='mb-3'>
        <BaseFilter rowid={rowid} handler={internalHandler} config={internalRowConfig} />
      </Row>
      <Row className='mb-3'>
        <Col className={'d-flex flex-column'}>
          <ManualSortation
            {...props}
            sortationKey={'manualSortation'}
            sortationConfig={internalRowConfig?.sortation ?? {}}
            handler={internalHandler}
          />
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col className={'d-flex flex-column'}>
          <FrequencyGroups {...props} />
        </Col>
      </Row>*/}
    </>
  );
};
