import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useSubelementPropertyTranslation } from 'smv-helpers';
import { Cell } from '..';
import { getHighestHeadBase } from './helper';

export const ResultRow = ({
  children,
  rowid,
  row,
  orderItem,
  subelement,
  choiceid,
  scaleid = null,
  heads,
  config,
  tableResult,
  handler,
  isHidden = false,
  isExcluded = false,
  sortationPinned = null,
  isCompactMatrix = false,
  isDetailedMatrix = false,
  headMappingTable = {},
  menu = null,
  metric = 'smvrslt_perc',
  hover
}) => {
  const { showTotal = true, showCodeValuesRow = false, showHiddenColumns = true } = config;
  const intl = useIntl();

  const subelementPropertyTranslation = useSubelementPropertyTranslation(subelement, orderItem, {
    showCodeValue: showCodeValuesRow,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const label =
    metric === 'smvrslt_mean'
      ? intl.formatMessage({
          id: `smoove.common.result.aggregation.types.mean`,
          defaultMessage: 'mean value'
        })
      : subelementPropertyTranslation;

  const getResultCell = useCallback(
    (headid, subelementid) => {
      if (scaleid) return tableResult?.values?.[rowid]?.[choiceid]?.[scaleid]?.[headid]?.[subelementid];
      else return tableResult?.values?.[rowid]?.[choiceid]?.[headid]?.[subelementid];
    },
    [tableResult, rowid, choiceid, scaleid]
  );

  return (
    <tr className={'result__row'}>
      {children}

      {/* Row Label */}
      <th scope='row' className={classNames('row__header', { 'row__header--hidden': isHidden || isExcluded })}>
        <div className={'row__header_label'}>
          <span className={`row__header_label--label`}>{label}</span>
          {menu}
          {sortationPinned !== null && (
            <i
              className='fal fa-thumbtack pr-1 pointer'
              onClick={() => handler.sortChangeElementPinned(sortationPinned, rowid, choiceid, scaleid)}
            />
          )}
        </div>
      </th>

      {/* Total Column */}
      {showTotal && !isCompactMatrix && (
        <Cell
          key={`${rowid}_${choiceid}_${'__total__'}`}
          cell={getResultCell('__total__', '__total__')}
          metric={metric}
          config={config}
          isHidden={isHidden}
          isExcluded={isExcluded}
          cellIdentifier={{
            rowId: rowid,
            rowSubId: choiceid,
            rowSubSubId: scaleid,
            headId: '__total__',
            headSubId: '__total__'
          }}
          hover={hover}
        />
      )}

      {/* Other Columns */}
      {heads.order.length > 0 &&
        heads.order.map(headid => {
          let _metric = metric;
          if (
            isCompactMatrix &&
            row?.sourceType !== 'calculation' &&
            heads.list[headid]?.sourceType !== 'calculation' &&
            headid !== rowid
          )
            return null;

          const { sourceType } = heads.list[headid];
          const headConfig = heads.list[headid].config ?? {
            hidden: {},
            excluded: {},
            sortation: {}
          };

          if (sourceType === 'calculation') {
            _metric = 'smvrslt_calculation';
          }

          let { hidden = {} } = headConfig;

          return tableResult?.headOrder?.[headid]?.map(orderItem => {
            const headMapping =
              Object.values(headMappingTable).find(mapping => mapping.ids.includes(orderItem.headSubId)) ?? {};
            /** @todo: refactor hidden heads to use headOrder */
            let isHiddenColumn = hidden?.[orderItem.headSubId] === true || headMapping?.hidden === true;
            if (!isHiddenColumn && (headConfig?.autoHideLowBases ?? false)) {
              const autoHideLowBasesHurdle = headConfig?.autoHideLowBasesHurdle ?? 0;
              const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
                isDetailedMatrix: isDetailedMatrix,
                excludeHidden: true
              });
              if (highestBase <= autoHideLowBasesHurdle) isHiddenColumn = true;
            }

            if (!showHiddenColumns && isHiddenColumn) return null;

            const _headchoiceid = headMapping?.idmap?.[headid] ?? orderItem.headSubId;

            return (
              <Cell
                key={`${rowid}_${choiceid}_${_headchoiceid}`}
                cell={getResultCell(headid, orderItem.headSubId)}
                metric={_metric}
                config={config}
                isHidden={isHidden || isHiddenColumn}
                isExcluded={isExcluded}
                cellIdentifier={{
                  rowId: rowid,
                  rowSubId: choiceid,
                  rowSubSubId: scaleid,
                  headId: headid,
                  headSubId: _headchoiceid
                }}
                hover={hover}
              />
            );
          });
        })}
    </tr>
  );
};
