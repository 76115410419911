import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useSubelementPropertyTranslation } from 'smv-helpers';
import { ResultTableChartCell } from '..';
import {
  getConditionalFormat,
  getHighestHeadBase,
  useDefaultMetric
} from 'src/smoove/components/project-results/ResultTable/result-rows/helper';

export const ResultTableChartResultRow = ({
  children = null,
  rowid,
  row,
  orderItem,
  subelement,
  choiceid,
  scaleid = null,
  heads,
  config,
  tableResult,
  conditionalFormats = { list: {}, order: [] },
  isCompactMatrix = false,
  isDetailedMatrix = false,
  headMappingTable = {},
  metric = 'smvrslt_perc',
  significancesShow = false,
  rowHeadColSpan = 1,
  hover
}) => {
  const locale = useSelector(
    state => state.projectSettings?.[state.survey.id]?.selectedLocale ?? state.survey.locales.main ?? 'en-US'
  );

  const defaultMetric = useDefaultMetric({ config });

  const { showTotal = true, showCodeValuesRow = false, showHiddenColumns = true } = config;
  const intl = useIntl();

  const subelementPropertyTranslation = useSubelementPropertyTranslation(subelement, orderItem, {
    locale,
    showCodeValue: showCodeValuesRow,
    useShortcodes: true,
    useStripped: true,
    useFallback: true
  });

  const label =
    metric === 'smvrslt_mean'
      ? intl.formatMessage({
          id: `smoove.common.result.aggregation.types.mean`,
          defaultMessage: 'mean value'
        })
      : subelementPropertyTranslation;

  const getResultCell = useCallback(
    (headid, subelementid) => {
      if (scaleid) return tableResult?.values?.[rowid]?.[choiceid]?.[scaleid]?.[headid]?.[subelementid];
      else return tableResult?.values?.[rowid]?.[choiceid]?.[headid]?.[subelementid];
    },
    [tableResult, rowid, choiceid, scaleid]
  );

  return (
    <tr className={'result__row'}>
      {children}

      {/* Row Label */}
      <th scope='row' className={'row__header'} colSpan={rowHeadColSpan}>
        <div className={'row__header_label'}>
          <span title={label} className={`row__header_label--label row__header`}>
            {label}
          </span>
        </div>
      </th>

      {/* Total Column */}
      {showTotal &&
        !isCompactMatrix &&
        (() => {
          const applyConditionalFormat = getConditionalFormat({
            conditionalFormats,
            tableResult,
            rowId: rowid,
            rowSubId: choiceid,
            rowSubSubId: scaleid,
            headId: '__total__',
            headSubId: '__total__',
            metric: metric,
            defaultMetric
          });

          return (
            <ResultTableChartCell
              key={`${rowid}_${choiceid}_${'__total__'}`}
              cell={getResultCell('__total__', '__total__')}
              metric={metric}
              config={config}
              cellIdentifier={{
                rowId: rowid,
                rowSubId: choiceid,
                rowSubSubId: scaleid,
                headId: '__total__',
                headSubId: '__total__'
              }}
              hover={hover}
              significancesShow={significancesShow}
              conditionalFormat={applyConditionalFormat}
            />
          );
        })()}

      {/* Other Columns */}
      {heads.order.length > 0 &&
        heads.order.map(headid => {
          let _metric = metric;
          if (
            isCompactMatrix &&
            row?.sourceType !== 'calculation' &&
            heads.list[headid].sourceType !== 'calculation' &&
            headid !== rowid
          )
            return null;
          const { sourceType, config: headConfig } = heads.list[headid];

          if (sourceType === 'calculation') {
            _metric = 'smvrslt_calculation';
          }

          let { hidden = {} } = headConfig;

          return tableResult?.headOrder?.[headid]?.map(orderItem => {
            const headMapping =
              Object.values(headMappingTable).find(mapping => mapping.ids.includes(orderItem.headSubId)) ?? {};

            /** @todo: refactor hidden heads to use headOrder */
            let isHiddenColumn = hidden?.[orderItem.headSubId] === true || headMapping?.hidden === true;
            if (!isHiddenColumn && (headConfig?.autoHideLowBases ?? false)) {
              const autoHideLowBasesHurdle = headConfig?.autoHideLowBasesHurdle ?? 0;
              const highestBase = getHighestHeadBase(tableResult, headid, orderItem.headSubId, {
                isDetailedMatrix: isDetailedMatrix,
                excludeHidden: true
              });
              if (highestBase <= autoHideLowBasesHurdle) isHiddenColumn = true;
            }

            if (isHiddenColumn) return null;
            if (!showHiddenColumns && isHiddenColumn) return null;

            const _headchoiceid = headMapping?.idmap?.[headid] ?? orderItem.headSubId;

            const cell = getResultCell(headid, _headchoiceid);

            const applyConditionalFormat = getConditionalFormat({
              conditionalFormats,
              tableResult,
              rowId: rowid,
              rowSubId: choiceid,
              rowSubSubId: scaleid,
              headId: headid,
              headSubId: orderItem.headSubId,
              metric: _metric,
              defaultMetric
            });

            return (
              <ResultTableChartCell
                key={`${rowid}_${choiceid}_${_headchoiceid}`}
                cell={cell}
                metric={_metric}
                config={config}
                cellIdentifier={{
                  rowId: rowid,
                  rowSubId: choiceid,
                  rowSubSubId: scaleid,
                  headId: headid,
                  headSubId: _headchoiceid
                }}
                hover={hover}
                significancesShow={significancesShow}
                conditionalFormat={applyConditionalFormat}
              />
            );
          });
        })}
    </tr>
  );
};

ResultTableChartResultRow.propTypes = {
  rowid: PropTypes.string.isRequired,
  choiceid: PropTypes.string.isRequired,
  scaleid: PropTypes.string,
  orderItem: PropTypes.object.isRequired,
  subelement: PropTypes.object.isRequired,
  heads: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  tableResult: PropTypes.object.isRequired,
  metric: PropTypes.string,
  headMappingTable: PropTypes.object,
  isCompactMatrix: PropTypes.bool.isRequired
};
