import { useCallback, useMemo, useState } from 'react';
import { Collapse, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

import { EditFiltersButtonAndModal } from '../modals';
import { ErrorBoundary } from '../../misc';
import { FilterElementInvalid } from './FilterElement';
import { FilterElement } from './';

import './Filters.scss';

export const Filters = ({ report, handler, activeFilters, readonly }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleOpen = useCallback(() => setIsOpen(state => !state), []);

  const filters = useMemo(() => {
    if (!report.filters || !report.filters.order || report.filters.order.length === 0)
      return <div className='ml-3'>no filters defined</div>;

    return report.filters.order.map(filterid => {
      return (
        <ErrorBoundary key={filterid} fallback={<FilterElementInvalid filterid={filterid} />}>
          <FilterElement filterid={filterid} reportid={report.id} handler={handler} activeFilters={activeFilters} />
        </ErrorBoundary>
      );
    });
  }, [report?.filters, handler, activeFilters, report.id]);

  return (
    <ListGroupItem className={'reports-list smv-filter-list'}>
      <ListGroupItemHeading className='pointer mb-0 py-2 list-item-sticky'>
        <div className='reports-list__headline'>
          <i onClick={toggleOpen} className={`far fa-chevron-${isOpen ? 'down' : 'right'}`}></i>
          <span onClick={toggleOpen} className='ml-1 reports-list__headline-text'>
            <FormattedMessage id={'smoove.page.common.filters.label'} defaultMessage={'Filters'} />
          </span>
          {!readonly && <EditFiltersButtonAndModal report={report} handler={handler} />}
        </div>
      </ListGroupItemHeading>
      <Collapse isOpen={isOpen}>{filters}</Collapse>
    </ListGroupItem>
  );
};
