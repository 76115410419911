import Axios from 'axios';
import { customAlphabet } from 'nanoid';
import { Store as notify } from 'react-notifications-component';
import { store, intl, dataPrepActions } from 'smv-redux';

import 'smv-redux/axiosConfig';
import { surveyActionTypes } from '../constants/surveyActionTypes';
// import { uploadMediaItem } from './helper';
// import { questionnaireActions } from './questionnaireActions';
// import { translationsActions } from './translationsActions';
import { projectActions } from './projectActions';
import { defaultNotifyProps } from 'smv-constants';
import { parseErrorAsMessageString } from 'src/smoove/helpers/parseErrorAsMessageString';

const resetSurvey = () => {
  store.dispatch({ type: surveyActionTypes.SWITCH_SURVEY });
};

/**
 * adds a new survey to a project using a wizard
 * @param {string} projectId
 * @param {object} data
 * @param {function} callbackFn //is passed the newly created survey un success or the error object if request fails
 *
 */
const addSurveyByWizard = async (projectId, data, callbackFn) => {
  store.dispatch({
    type: surveyActionTypes.ADD_SURVEY_REQUEST,
    payload: { projectId }
  });

  return Axios.post(`/projects/${projectId}/surveys`, data)
    .then(res => {
      const survey = res.data;
      store.dispatch({
        type: surveyActionTypes.ADD_SURVEY_SUCCESS,
        payload: {
          projectId,
          survey
        }
      });
      // todo: this works but is an extra request. is there an easier way to update the state (in the reducer?)
      projectActions.getAllSurveys(projectId);

      callbackFn(survey);

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: surveyActionTypes.ADD_SURVEY_FAILURE,
        payload: {
          projectId,
          error
        }
      });
      callbackFn(error);

      return error;
    });
};

/**
 * Duplicates a survey in a project
 *
 * @param {string} projectId
 * @param {string} surveyId
 */
export const duplicateSurvey = async (projectId, surveyId) => {
  store.dispatch({
    type: surveyActionTypes.DUPLICATE_SURVEY_REQUEST,
    payload: { projectId, surveyId }
  });

  return Axios.request({ method: 'copy', url: `/projects/${projectId}/surveys/${surveyId}`, timeout: 10 * 60 * 1000 })
    .then(res => {
      const survey = res.data;

      store.dispatch({
        type: surveyActionTypes.DUPLICATE_SURVEY_SUCCESS,
        payload: {
          projectId,
          survey
        }
      });

      // todo: this works but is an extra request. is there an easier way to update the state (in the reducer?)
      projectActions.getAllSurveys(projectId);

      notify.addNotification({
        title: 'Survey duplicated successfully',
        type: 'success',
        ...defaultNotifyProps
      });

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: surveyActionTypes.DUPLICATE_SURVEY_FAILURE,
        payload: {
          projectId,
          error
        }
      });
      notify.addNotification({
        title: 'An error occured while during duplication. Please try again',
        type: 'danger',
        ...defaultNotifyProps
      });

      return error;
    });
};

/**
 * removes a survey from a project
 * @param {string} projectId
 * @param {string} surveyID
 *
 */
export const removeSurvey = async (projectId, surveyId) => {
  store.dispatch({
    type: surveyActionTypes.REMOVE_SURVEY_REQUEST,
    payload: { projectId, surveyId }
  });

  return Axios.delete(`/projects/${projectId}/surveys/${surveyId}`)
    .then(res => {
      const survey = res.data;

      store.dispatch({
        type: surveyActionTypes.REMOVE_SURVEY_SUCCESS,
        payload: {
          projectId,
          survey
        }
      });

      // todo: this works but is an extra request. is there an easier way to update the state (in the reducer?)
      // todo: does this twhrow an error?
      projectActions.getAllSurveys(projectId);

      return res;
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: surveyActionTypes.REMOVE_SURVEY_FAILURE,
        payload: {
          projectId,
          error
        }
      });

      return error;
    });
};

/**
 * Loads the configuration for a specified survey
 * @param {string} projectid
 * @param {string} surveyId
 * @param {object} fields - fieilds to be pupulated in survey with value -1
 * @param {string} fields.questionnaire
 * @param {string} fields.translations
 * @param {string} fields.reports
 * @param {string} fields.folders
 * @param {string} fields.tables
 */

const getSurvey = (surveyId, fields) => {
  store.dispatch({
    type: surveyActionTypes.LOAD_SURVEY_REQUEST,
    payload: surveyId
  });

  return Axios.get(`/surveys/${surveyId}`, { params: fields })
    .then(res => {
      const survey = res.data;
      store.dispatch({
        type: surveyActionTypes.LOAD_SURVEY_SUCCESS,
        payload: {
          survey
        }
      });
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.LOAD_SURVEY_FAILURE,
        payload: {
          surveyId,
          error: err
        }
      });
    });
};

/**
 * Updates the survey configuration
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {object} config //the element that will be updated, not the whole config (eg: {title: "title"}). Root elements only (or whole objects if element is nested)
 */
const updateSurvey = (projectid, surveyid, element) => {
  store.dispatch({
    type: surveyActionTypes.UPDATE_SURVEY_REQUEST,
    payload: { surveyid }
  });

  Axios.patch(`/surveys/${surveyid}`, element)
    .then(res => {
      const survey = res.data;

      store.dispatch({
        type: surveyActionTypes.UPDATE_SURVEY_SUCCESS,
        payload: {
          surveyid,
          projectid,
          survey
        }
      });

      return survey;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.UPDATE_SURVEY_FAILURE,
        payload: {
          surveyid,
          projectid,
          error: err
        }
      });
    });
};
/**
 * Reporders the surveys reports order
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {object} newReportsOrder //{ reports: ["id0", "id1"] }
 */
const reorderSurveyReports = (projectid, surveyid, newReportsOrder) => {
  store.dispatch({
    type: surveyActionTypes.REORDER_SURVEY_REPORTS_REQUEST,
    payload: { surveyid }
  });

  Axios.patch(`/surveys/${surveyid}`, newReportsOrder)
    .then(res => {
      const survey = res.data;
      console.log(res);

      store.dispatch({
        type: surveyActionTypes.REORDER_SURVEY_REPORTS_SUCCESS,
        payload: {
          surveyid,
          projectid,
          survey
        }
      });

      return survey;
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.REORDER_SURVEY_REPORTS_FAILURE,
        payload: {
          surveyid,
          projectid,
          error: err
        }
      });
    });
};

/**
 *
 * @param {string} projectid
 * @param {string} surveyid
 */
const launchSurvey = (projectid, surveyid) => {
  store.dispatch({
    type: surveyActionTypes.LAUNCH_SURVEY_REQUEST,
    payload: surveyid
  });
  Axios.get(`/surveys/${surveyid}/launch`)
    .then(res => {
      const survey = res.data;

      store.dispatch({
        type: surveyActionTypes.LAUNCH_SURVEY_SUCCESS,
        payload: {
          surveyid,
          projectid,
          survey
        }
      });
      notify.addNotification({
        title: 'Successfully transfered survey to tivian',
        type: 'success',
        ...defaultNotifyProps
      });
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.LAUNCH_SURVEY_FAILURE,
        payload: {
          surveyid,
          error: err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps,
        dismiss: {
          duration: 5000,
          showIcon: true
        }
      });
    });
};

/**
 *
 * @param {string} projectid
 * @param {string} surveyid
 */
const testSurvey = (projectid, surveyid) => {
  store.dispatch({
    type: surveyActionTypes.START_TEST_SURVEY_REQUEST,
    payload: surveyid
  });
  Axios.get(`/surveys/${surveyid}/test`, { timeout: 5 * 60 * 1000 })
    .then(res => {
      const survey = res.data;

      store.dispatch({
        type: surveyActionTypes.START_TEST_SURVEY_SUCCESS,
        payload: {
          surveyid,
          projectid,
          survey
        }
      });
      notify.addNotification({
        title: 'Successfully started testing mode',
        type: 'success',
        ...defaultNotifyProps
      });
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.START_TEST_SURVEY_FAILURE,
        payload: {
          surveyid,
          error: err
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(err),
        type: 'danger',
        ...defaultNotifyProps,
        dismiss: {
          duration: 5000,
          showIcon: true
        }
      });
    });
};

/**
 * Generates synthetic responses
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {integer} cases
 */
const generateDummyData = (surveyid, cases = 100) => {
  store.dispatch({
    type: surveyActionTypes.GENERATE_DUMMY_DATA_REQUEST,
    payload: { surveyid, cases }
  });

  Axios.get(`/surveys/${surveyid}/syntheticdata`, { params: { cases } })
    .then(res => {
      store.dispatch({
        type: surveyActionTypes.GENERATE_DUMMY_DATA_SUCCESS,
        payload: res.data
      });

      notify.addNotification({
        title: intl.formatMessage({
          id: 'generate-dummy-data-success',
          defaultMessage: 'Successfully generated the dymmy data.'
        }),
        type: 'success',
        ...defaultNotifyProps
      });
    })
    .catch(error => {
      console.log(error);
      store.dispatch({
        type: surveyActionTypes.GENERATE_DUMMY_DATA_FAILURE,
        payload: {
          error
        }
      });

      notify.addNotification({
        title: parseErrorAsMessageString(error),
        type: 'danger',
        ...defaultNotifyProps
      });
    });
};

/**
 * Saves/ updates a survey wizard configuration
 *
 * @param {string} projectid Project id
 * @param {string} surveyid Survey id
 * @param {object} wizard WizardState contents {type: '', countries: [], ... }
 *
 * @returns {Promise} Axis Post request
 */
const saveWizard = (projectid, surveyid, wizard) => {
  const data = new FormData();
  data.append(
    'payload',
    JSON.stringify({
      projectid,
      surveyid,
      content: wizard
    })
  );
  console.log('TODO: Add functionalty to save a wizard state in the new backend');
  // return Axios.post(`${'/jsonrpc.php'}`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XSurvey',
  //     action: 'saveWizard'
  //   }
  // })
  //   .then(response => {
  //     if (response.data.result.status) {
  //       return { projectid, surveyid, wizard };
  //     } else {
  //       return false;
  //     }
  //   })
  //   .catch(error => {
  //     console.log(error);
  //     return false;
  //   });
};

/**
 * Saves/ updates a questionnaire element in the surveys questionnaire
 *
 * @param {string} surveyid
 * @param {object} content
 */
const saveQuestionnaireElement = (surveyid, content) => {
  console.warn(
    'Deprecated! use questionnaireActions.updateQuestionnaireElement or questionnaireActions.addQuestionnaireElement'
  );
  // todo: use new questionnaireActions.updateQuestionnaireElement
  // store.dispatch({
  //   type: surveyActionTypes.SAVE_SURVEY_ELEMENT_REQUEST,
  //   payload: { elementid: content.id }
  // });

  // const data = new FormData();
  // data.append(
  //   'payload',
  //   JSON.stringify({
  //     projectid: surveyid,
  //     surveyid: surveyid,
  //     content: content
  //   })
  // );

  // Axios.post(`/jsonrpc.php`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XSurvey',
  //     action: 'saveQuestion'
  //   }
  // })
  //   .then(res => {
  //     const { element, translations } = res.data.result;
  //     store.dispatch({
  //       type: surveyActionTypes.SAVE_SURVEY_ELEMENT_SUCCESS,
  //       payload: {
  //         elementid: element.id,
  //         element: element,
  //         translations: translations
  //       }
  //     });
  //   })
  //   .catch(err => {
  //     store.dispatch({
  //       type: surveyActionTypes.SAVE_SURVEY_ELEMENT_FAILURE,
  //       payload: {
  //         elementid: content.id,
  //         error: err
  //       }
  //     });
  //   });
};

/**
 * Duplicates a questionnaire element and appends it below the selected element.
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {string} elementid
 */
// const duplicateQuestionnaireElement = (projectid, surveyid, elementid) => {
//   console.warn('Deprecated! Implemented in questionnaire as "copyQuestionnaireElement"');
// store.dispatch({
//   type: surveyActionTypes.SAVE_SURVEY_ELEMENT_REQUEST,
//   payload: { elementid }
// });

// const data = new FormData();
// data.append(
//   'payload',
//   JSON.stringify({
//     projectid,
//     surveyid,
//     elementid
//   })
// );

// Axios.post(`/jsonrpc.php`, data, {
//   params: {
//     program: 'Smoove',
//     controller: 'XSurvey',
//     action: 'duplicateQuestion'
//   }
// })
//   .then(res => {
//     const { order, element, position, parent, translations } = res.data.result;
//     store.dispatch({
//       type: surveyActionTypes.SAVE_SURVEY_ELEMENT_SUCCESS,
//       payload: {
//         created: true,
//         elementid: element.id,
//         element: element,
//         parentid: parent.id,
//         order: order,
//         position: position,
//         translations: translations
//       }
//     });

//     return { status: true, projectid, surveyid };
//   })
//   .catch(err => {
//     store.dispatch({
//       type: surveyActionTypes.SAVE_SURVEY_ELEMENT_FAILURE,
//       payload: {
//         elementid,
//         error: err
//       }
//     });
//   });
// };

/**
 * Saves a subelement config
 *
 * @param {array} pathids Containing the direct element path surveyid => elementid [=> subid e.g. choiceid or scaleid]
 * @param {string} type Subelement type, currently: 'choice' or 'scale'
 * @param {object} content Subelement config
 */
const saveSubElement = (pathids, type, content) => {
  console.warn('deprecated function "surveyActions.saveSubElement" is used. new implementation tba');
  // external function to validata questionnaire element contents based
  // on content type (question, page, container)
  // getQuestionnaireElement(_content)

  // store.dispatch({
  //   type: surveyActionTypes.SAVE_SURVEY_SUBELEMENT_REQUEST,
  //   payload: { elementid: pathids[1] }
  // });

  // const data = new FormData();
  // data.append(
  //   'payload',
  //   JSON.stringify({
  //     pathids: pathids,
  //     type: type,
  //     content: content
  //   })
  // );

  // Axios.post(`/jsonrpc.php`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XQuestion',
  //     action: 'saveSubelement'
  //   }
  // })
  //   .then(res => {
  //     const { element, elementid, translations } = res.data.result;
  //     try {
  //       store.dispatch({
  //         type: surveyActionTypes.SAVE_SURVEY_SUBELEMENT_SUCCESS,
  //         payload: {
  //           elementid: elementid,
  //           element: element,
  //           translations: translations
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })
  //   .catch(err => {
  //     store.dispatch({
  //       type: surveyActionTypes.SAVE_SURVEY_SUBELEMENT_FAILURE,
  //       payload: {
  //         elementid: pathids[1],
  //         error: err
  //       }
  //     });
  //   });
};

/**
 * Saves multiple subelement configs at once
 *
 * @param {string} projectid
 * @param {string} surveyid
 * @param {string} elementid
 * @param {string} type subelement type (`choice` or `scale`)

 * @param {array} subelements array of subelement configs without
 */
const saveSubElements = (projectid, surveyid, elementid, type, subelements) => {
  console.warn('deprecated function "surveyActions.saveSubElements" is used. new implementation TBA');
  // store.dispatch({
  //   type: surveyActionTypes.SAVE_SURVEY_SUBELEMENT_REQUEST,
  //   payload: { elementid }
  // });

  // const data = new FormData();
  // data.append(
  //   'payload',
  //   JSON.stringify({
  //     projectid,
  //     surveyid,
  //     elementid,
  //     type,
  //     subelements
  //   })
  // );

  // Axios.post(`/jsonrpc.php`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XQuestion',
  //     action: 'saveSubelements'
  //   }
  // })
  //   .then(res => {
  //     const { element, elementid, translations } = res.data.result;
  //     try {
  //       store.dispatch({
  //         type: surveyActionTypes.SAVE_SURVEY_SUBELEMENT_SUCCESS,
  //         payload: {
  //           elementid: elementid,
  //           element: element,
  //           translations: translations
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })
  //   .catch(err => {
  //     store.dispatch({
  //       type: surveyActionTypes.SAVE_SURVEY_SUBELEMENT_FAILURE,
  //       payload: {
  //         elementid,
  //         error: err
  //       }
  //     });
  //   });
};

const saveSurveyPartsAll = (projectid, surveyid, surveyParts) => {
  console.warn('deprecated function "surveyActions.saveSurveyPartsAll" is used');
  // store.dispatch({
  //   type: surveyActionTypes.DELETE_SURVEY_SUBELEMENT_REQUEST,
  //   payload: { elementid: pathids[1] }
  // });

  // const data = new FormData();
  // data.append(
  //   'payload',
  //   JSON.stringify({
  //     projectid,
  //     surveyid,
  //     surveyParts
  //   })
  // );

  // return Axios.post(`/jsonrpc.php`, data, {
  //   params: {
  //     program: 'Smoove',
  //     controller: 'XSurvey',
  //     action: 'savePartsAll'
  //   }
  // })
  //   .then(response => {
  //     const { status } = response.data.result;
  //     if (status) {
  //       // store.dispatch({
  //       //   type: surveyActionTypes.DELETE_SURVEY_SUBELEMENT_SUCCESS,
  //       //   payload: {
  //       //     elementid: elementid,
  //       //     element: element,
  //       //     translations: translations
  //       //   }
  //       // });

  //       return { status: true, projectid, surveyid };
  //     } else {
  //       return false;
  //     }
  //   })
  //   .catch(err => {
  //     // store.dispatch({
  //     //   type: surveyActionTypes.DELETE_SURVEY_SUBELEMENT_FAILURE,
  //     //   payload: {
  //     //     elementid: pathids[1],
  //     //     error: err
  //     //   }
  //     // });
  //     console.log(err);

  //     return false;
  //   });
};

/**
 * Uploads a media element to a survey
 *
 * @param {string} surveyid
 * @param {file} mediaFile
 * @param {function} callbackFn
 *
 */
const uploadMediaElement = (surveyid, mediaFile, callbackFn = () => {}) => {
  const mediaid = mediaFile?.id ?? customAlphabet('1234567890abcdef', 24)();

  store.dispatch({
    type: surveyActionTypes.UPLOAD_MEDIA_REQUEST,
    payload: { surveyid, mediaid }
  });

  const fd = new FormData();
  fd.append('file', mediaFile);
  fd.append('name', mediaFile.name);

  Axios.post(`/surveys/${surveyid}/media`, fd, { timeout: 10 * 60 * 1000 })
    .then(response => {
      const media = response.data;
      store.dispatch({
        type: surveyActionTypes.UPLOAD_MEDIA_SUCCESS,
        payload: {
          surveyid,
          media
        }
      });

      callbackFn(false);
      return { surveyid, media };
    })
    .catch(err => {
      console.log(err);
      callbackFn(err);
      store.dispatch({
        type: surveyActionTypes.UPLOAD_MEDIA_FAILURE,
        payload: { error: err }
      });
    });
};

/**
 * Updates a media element
 *
 * @param {string} surveyid
 * @param {string} mediaid
 * @param {file} mediaFile
 *
 */
const updateMediaElement = (surveyid, mediaid, mediaFile) => {
  store.dispatch({
    type: surveyActionTypes.UPLOAD_MEDIA_REQUEST,
    payload: { surveyid, mediaid }
  });

  const fd = new FormData();
  fd.append('file', mediaFile);
  fd.append('name', mediaFile.name);

  Axios.put(`/surveys/${surveyid}/media/${mediaid}`, fd, { timeout: 10 * 60 * 1000 })
    .then(response => {
      const media = response.data;
      store.dispatch({
        type: surveyActionTypes.UPLOAD_MEDIA_SUCCESS,
        payload: {
          surveyid,
          media
        }
      });

      return { surveyid, media };
      // callbackFn();
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.UPLOAD_MEDIA_FAILURE,
        payload: { error: err }
      });
    });
};

/**
 * Deletes a media element
 *
 * @param {string} surveyid
 * @param {string} mediaid
 *
 */
const deleteMediaElement = (surveyid, mediaid) => {
  store.dispatch({
    type: surveyActionTypes.DELETE_MEDIA_REQUEST,
    payload: { surveyid, mediaid }
  });

  Axios.delete(`/surveys/${surveyid}/media/${mediaid}`)
    .then(response => {
      store.dispatch({
        type: surveyActionTypes.DELETE_MEDIA_SUCCESS,
        payload: { surveyid, mediaid }
      });
    })
    .catch(err => {
      console.log(err);
      store.dispatch({
        type: surveyActionTypes.DELETE_MEDIA_FAILURE,
        payload: {
          surveyid,
          mediaid,
          error: err
        }
      });

      return false;
    });
};

const runDataImport = (surveyid, flush, calculations) => {
  // store.dispatch({
  //   type: surveyActionTypes.GENERATE_DUMMY_DATA_REQUEST,
  //   payload: { surveyid, cases }
  // });
  return new Promise((resolve, reject) => {
    Axios.get(`/surveys/${surveyid}/rundataimport`, {
      params: { flush: flush ? 1 : 0, calculations: calculations ? 1 : 0 },
      timeout: 5 * 60 * 1000
    })
      .then(res => {
        // store.dispatch({
        //   type: surveyActionTypes.GENERATE_DUMMY_DATA_SUCCESS,
        //   payload: res.data
        // });
        resolve(res);

        // notify.addNotification({
        //   title: intl.formatMessage({
        //     id: 'survey-data-import-success',
        //     defaultMessage: 'Successfully imported data.'
        //   }),
        //   type: 'success',
        //   ...defaultNotifyProps
        // });
      })
      .catch(error => {
        console.log(error);
        reject(error);
        // store.dispatch({
        //   type: surveyActionTypes.GENERATE_DUMMY_DATA_FAILURE,
        //   payload: {
        //     error
        //   }
        // });

        notify.addNotification({
          title: parseErrorAsMessageString(
            error,
            intl.formatMessage({
              id: 'survey-data-import-error',
              defaultMessage: 'Failed to import data.'
            })
          ),
          type: 'danger',
          ...defaultNotifyProps
        });
      });
  });
};

const runCsvDataImport = (surveyid, file, flush, calculations) => {
  // store.dispatch({
  //   type: surveyActionTypes.GENERATE_DUMMY_DATA_REQUEST,
  //   payload: { surveyid, cases }
  // });

  const fd = new FormData();
  fd.append('file', file);
  fd.append('name', file.name);

  return new Promise((resolve, reject) => {
    Axios.post(`/surveys/${surveyid}/rundatafileimport`, fd, {
      params: { flush: flush ? 1 : 0, calculations: calculations ? 1 : 0 },
      timeout: 5 * 60 * 1000
    })
      .then(res => {
        // store.dispatch({
        //   type: surveyActionTypes.GENERATE_DUMMY_DATA_SUCCESS,
        //   payload: res.data
        // });
        resolve(res);
      })
      .catch(error => {
        console.log(error);
        // store.dispatch({
        //   type: surveyActionTypes.GENERATE_DUMMY_DATA_FAILURE,
        //   payload: {
        //     error
        //   }
        // });

        notify.addNotification({
          title: parseErrorAsMessageString(
            error,
            intl.formatMessage({
              id: 'survey-data-import-error',
              defaultMessage: 'Failed to import data.'
            })
          ),
          type: 'danger',
          ...defaultNotifyProps
        });
        reject(error);
      });
  });
};

/**
 * Performs calculation of calculation variables
 *
 * @param {string} surveyid
 */
const runDataCalculations = surveyid => {
  store.dispatch({
    type: surveyActionTypes.RUN_DATA_CALCULATIONS_REQUEST,
    payload: { surveyid }
  });
  return new Promise((resolve, reject) => {
    Axios.get(`/surveys/${surveyid}/runcalculations`, { timeout: 5 * 60 * 1000 })
      .then(res => {
        store.dispatch({
          type: surveyActionTypes.RUN_DATA_CALCULATIONS_SUCCESS,
          payload: {
            data: res.data
          }
        });

        notify.addNotification({
          title: 'Data calculation finished.',
          type: 'success',
          ...defaultNotifyProps
        });

        dataPrepActions.getDataPrepBySurveyId(surveyid);

        resolve(res);
      })
      .catch(error => {
        console.log(error);
        store.dispatch({
          type: surveyActionTypes.RUN_DATA_CALCULATIONS_FAILURE,
          payload: {
            error: error
          }
        });

        notify.addNotification({
          title: parseErrorAsMessageString(
            error,
            intl.formatMessage({
              id: 'survey-data-calculation-error',
              defaultMessage: 'Failed to perform data calculations.'
            })
          ),
          type: 'danger',
          ...defaultNotifyProps
        });
        reject(error);
      });
  });
};

export const surveyActions = {
  // survey actions
  resetSurvey,
  addSurveyByWizard,
  duplicateSurvey,
  removeSurvey,
  getSurvey,
  updateSurvey,
  reorderSurveyReports,
  launchSurvey,
  testSurvey,
  generateDummyData,

  // wizard
  saveWizard,
  saveSurveyPartsAll,

  // generic translation actions

  // question actions
  saveQuestionnaireElement,
  // duplicateQuestionnaireElement,

  // subelement actions
  saveSubElement,
  saveSubElements,
  // moveSubElement,

  // media actions
  uploadMediaElement,
  updateMediaElement,
  deleteMediaElement,

  // async data handling
  runDataImport,
  runCsvDataImport,
  runDataCalculations
};
