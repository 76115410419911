import { reportsActions } from 'smv-redux';

/**
 *
 * A asyncrhonous function that creates charts in a report. Makes one request per provided table and updates the redux state by using redux actions to create a chart.
 *
 * @param {array}  tables //array of tables
 * @param {object} report //the report the charts should be created in
 * @param {string} pageId //pageId the new elements should be added to
 * @param {function} counter //a function that increments a value each time a chart has been created (eg a react useState set-function)
 *
 *  * @returns {Promise} Returns a promise
 */
export const asyncCreateCharts = (tables, report, pageId, counter) => {
  return new Promise((resolve, reject) => {
    const createChart = (idx, tables, layouts) => {
      const _table = tables[idx];
      const element = {
        sourceType: 'table',
        sourceId: _table.id
      };

      const isOdd = idx % 2;
      const elementYPosition = Math.floor(idx / 2) * 10;

      const newLayoutElement = {
        w: 6,
        h: 10,
        x: isOdd ? 6 : 0,
        y: elementYPosition,
        i: 'new_element'
      };
      layouts['lg'].push(newLayoutElement);
      layouts['md'].push(newLayoutElement);
      layouts['sm'].push(newLayoutElement);

      reportsActions
        .createDashboardElement(report.id, pageId, element, layouts)
        .then(res => {
          if (idx + 1 < tables.length) {
            counter(idx + 1);
            createChart(idx + 1, tables, res.layouts);
          } else {
            resolve();
          }
        })
        .catch(err => {
          reject(err);
          console.log(err);
        });
    };
    // creating charts recursively
    createChart(0, tables, { lg: [], md: [], sm: [] });
  });
};
