import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Responsive as GridLayout } from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import _, { cloneDeep } from 'lodash';
import produce from 'immer';
import { useSelector } from 'react-redux';

import { SmvDrawer } from 'smv-components/misc/SmvDrawer';
import { ConfigDrawerRight } from 'smv-components/project-reports';
import { useCanDrop } from 'src/smoove/helpers';
import { reportsActions } from '../../../redux/actions';
import { gridBreakpoints } from '../helper';
import { DashboardElement } from '../../dashboard';
import { ActiveFilterList } from '../Filters';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './Grid.scss';

export const Grid = ({
  surveyId,
  report,
  pageid,
  previewSize,
  setPreviewSize,
  reportsHandler,
  activeSplits,
  activeFilters,
  readonly = false,
  size
}) => {
  const page = report.pages?.list?.[pageid];
  const [copyid, setCopyid] = useState(null);
  const { canDrop } = useCanDrop(e => !!e.dataTransfer.getData('sourceType'));

  const reportFolders = useSelector(
    state =>
      state.survey.reports.reportFolders ?? {
        list: {},
        // order: [],
        rootFolderId: ''
      }
  );
  const [isConfigDrawerOpen, setIsConfigDrawerOpen] = useState(false);
  const [configDrawerElement, setConfigDrawerElement] = useState(null);
  const toggleConfigDrawer = useCallback(
    () =>
      setIsConfigDrawerOpen(isOpen => {
        if (isOpen) setConfigDrawerElement(null);
        return !isOpen;
      }),
    []
  );

  const nElements = Object.keys(page?.elements ?? {}).length;
  useEffect(() => {
    if (!copyid) {
      return;
    }
    const el = document.querySelector(`#dashboard-element-${copyid}`);

    if (!el) {
      return;
    }

    el.scrollIntoView({ behavior: 'smooth' });
    el.style.outline = '2px solid #8aa91b';
    setCopyid(null);
    setTimeout(function () {
      el.style.outline = '';
    }, 3000);
  }, [copyid, nElements]);

  const handler = useMemo(() => {
    return {
      openConfigDrawer: elementid => {
        setConfigDrawerElement(page.elements[elementid]);
        toggleConfigDrawer();
      },
      onLayoutChange: currentViewportLayouts => {
        const filteredfViewportLayouts = currentViewportLayouts.map(({ i, w, h, x, y }) => {
          return { i, w, h, x, y };
        });
        const oldViewportLayout = report.pages.list[pageid].layouts[previewSize];

        if (!_.isEqual(oldViewportLayout, filteredfViewportLayouts)) {
          reportsActions.updateLayout(report.id, pageid, previewSize, filteredfViewportLayouts);
        }
      },
      dropElement: (viewportLayouts, layoutItem, event) => {
        const sourceType = event.dataTransfer.getData('sourceType');

        if (readonly || !sourceType) return false;

        let element = { sourceType };

        if (sourceType === 'table') {
          const sourceId = event.dataTransfer.getData('sourceId');
          element.sourceId = sourceId;
        }
        if (sourceType === 'media') {
          const mediaType = event.dataTransfer.getData('mediaType');
          element = {
            ...element,
            sourceType: sourceType,
            mediaConfig: {
              mediaType: mediaType
            }
          };
        }

        let layouts = {};

        const { w, h, x, y } = layoutItem;

        if (!page.layouts) {
          layouts = {
            lg: [{ w, h, x, y, i: 'new_element' }],
            md: [{ h, x, y, i: 'new_element', w: Math.ceil(w / 6) * 6 }],
            sm: [{ h, x, y, i: 'new_element', w: Math.ceil(w / 6) * 6 }]
          };
        } else {
          layouts = cloneDeep(page.layouts);
          // filters out only required properties of the current vieport layouts
          // and  asign them to current viewport
          layouts[previewSize] = viewportLayouts.map(({ i, w, h, x, y }) => {
            return { i, w, h, x, y };
          });

          const restViewports = ['lg', 'md', 'sm'].filter(viewport => viewport !== previewSize);
          // add element to other viewports
          restViewports.forEach(viewport => {
            layouts[viewport].push({ w, h, x, y, i: 'new_element' });
          });
        }

        reportsActions.createDashboardElement(report.id, pageid, element, layouts).then(res => {
          // --- c&a folder logic update start ---
          const rootFolderId = reportFolders.rootFolderId;

          if (rootFolderId && rootFolderId.trim() !== '') {
            const rootFolder = reportFolders.list[rootFolderId];

            const _folder = produce(rootFolder, draftState => {
              draftState.children = [...rootFolder.children, { type: 'dashboardElement', id: res.element.id }];
            });

            reportsActions.updateFolder(report.id, pageid, rootFolderId, _folder).then(r => {
              reportsActions.loadFolders(report.id, pageid);
            });
          }
          // --- c&a folder logic update end ---
        });
      },
      deleteElement: elementId => {
        if (readonly) return false;
        reportsActions.deleteDashboardElement(report.id, page.id, elementId).then(res => {
          // --- c&a folder logic update start ---
          const removeChildById = (obj, childId) => {
            let updatedElement = null;
            // Iterate through each element in the list
            for (let key in obj.list) {
              if (obj.list.hasOwnProperty(key)) {
                let children = obj.list[key].children;

                // Find the index of the child with the matching id
                const childIndex = children.findIndex(child => child.id === childId);

                // If the child is found, remove it from the array
                if (childIndex !== -1) {
                  children.splice(childIndex, 1);
                  updatedElement = obj.list[key]; // Store the updated element
                  break; // Break the loop since we've found and removed the child
                }
              }
            }

            return updatedElement;
          };

          const _folder = removeChildById(reportFolders, elementId);

          if (_folder) {
            reportsActions.updateFolder(report.id, pageid, _folder?.id, _folder).then(r => {
              reportsActions.loadFolders(report.id, pageid);
            });
          }
          // --- c&a folder logic update end ---
        });
      },
      copyElement: elementid => {
        if (readonly) return false;

        reportsActions.duplicateDashboardElement(report.id, pageid, elementid).then(({ page, elementCopy }) => {
          setCopyid(elementCopy.id);
          // --- c&a folder logic update start ---
          const rootFolderId = reportFolders.rootFolderId;

          if (rootFolderId && rootFolderId.trim() !== '') {
            const rootFolder = reportFolders.list[rootFolderId];

            const _folder = produce(rootFolder, draftState => {
              draftState.children = [...rootFolder.children, { type: 'dashboardElement', id: elementCopy.id }];
            });

            reportsActions.updateFolder(report.id, pageid, rootFolderId, _folder).then(r => {
              reportsActions.loadFolders(report.id, pageid);
            });
          }
          // --- c&a folder logic update end ---
        });
      },
      exportElement: (elementid, exportFileType, exportLoopFilters = null) => {
        reportsHandler.exportElement(report.id, page.id, elementid, exportFileType, exportLoopFilters);
      }
    };
  }, [page, pageid, previewSize, readonly, report, reportsHandler, toggleConfigDrawer, reportFolders]);

  /**
   * create dashboard elements list
   */
  const elements = useMemo(() => {
    return Object.values(page?.elements ?? {}).map(element => {
      return (
        <div
          id={`dashboard-element-${element.id}`}
          key={element.id}
          className={'smv-result-report-element'}
          // data-grid={_layout}
        >
          <DashboardElement
            elementid={element.id}
            tableid={element.sourceId}
            reportid={report.id}
            pageid={page?.id}
            chartConfig={element.chartConfig}
            activeSplits={activeSplits}
            activeFilters={activeFilters}
            handler={handler}
            reportsHandler={reportsHandler}
            readonly={readonly}
          />
        </div>
      );
    });
  }, [activeFilters, activeSplits, handler, page?.id, page?.elements, readonly, report.id, reportsHandler]);

  const onBreakpointChange = useCallback(
    (newBreakpoint, newCols) => {
      setPreviewSize?.(newBreakpoint);
    },
    [setPreviewSize]
  );

  if (!page) return <div>No page. Please create a new page</div>;

  return (
    <div className={`smv-result-report ${_.isEmpty(page?.elements) ? 'smv-result-report--empty' : ''}`}>
      <h4 className={`smv-result-report__page--title`}>{page?.name}</h4>
      <ActiveFilterList activeFilters={activeFilters} report={report} reportsHandler={reportsHandler} />
      <GridLayout
        width={size.width}
        // measureBeforeMount={true}
        onBreakpointChange={onBreakpointChange}
        layouts={page?.layouts}
        compactType={'vertical'}
        breakpoints={{
          lg: gridBreakpoints.lg,
          md: gridBreakpoints.md,
          sm: gridBreakpoints.sm
        }}
        cols={{ lg: 12, md: 12, sm: 12 }}
        rowHeight={30}
        onDrop={handler.dropElement}
        isDroppable={!readonly && canDrop}
        isDraggable={!readonly}
        isResizable={!readonly}
        isBounded={true}
        droppingItem={{ i: 'new_element', w: 6, h: 10 }}
        // onLayoutChange={handler.onLayoutChange}
        onDragStop={handler.onLayoutChange}
        onResizeStop={handler.onLayoutChange}
        draggableHandle={'.card-header'}
      >
        {elements}
      </GridLayout>

      {page && (
        <SmvDrawer side={'right'} isOpen={isConfigDrawerOpen} toggle={toggleConfigDrawer}>
          <ConfigDrawerRight report={report} page={page} isOpen={isConfigDrawerOpen} element={configDrawerElement} />
        </SmvDrawer>
      )}
    </div>
  );
};

export const GridWithSize = React.memo(withSize()(Grid));
