import React, { useCallback } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Store as notificationStore } from 'react-notifications-component';

import { surveyActions } from 'smv-redux';
import { ConfirmAction } from 'smv-components';
import { defaultNotifyProps } from 'src/smoove/constants';

export const PerformCalculationsButton = () => {
  const surveyId = useSelector(state => state.survey.id);
  const isRunningCalculations = useSelector(state => state.survey?.isRunningCalculations);

  const intl = useIntl();

  const recalculateHandling = useCallback(() => {
    notificationStore.addNotification({
      type: 'success',
      insert: 'bottom',
      container: 'bottom-center',
      title: intl.formatMessage({ id: 'smoove.page.data.perform-calculations.calculation-triggered' }),
      message: intl.formatMessage({ id: 'smoove.page.data.perform-calculations.calculation-triggered-hint' }),
      dismiss: {
        showIcon: true,
        duration: 3000,
        onScreen: true,
        pauseOnHover: true
      }
    });
    surveyActions.runDataCalculations(surveyId).catch(err => {
      notificationStore.addNotification({
        type: 'danger',
        title: intl.formatMessage({
          id: 'survey-data-calculation-error',
          defaultMessage: 'Failed to perform data calculations.'
        }),
        ...defaultNotifyProps
      });
    });
  }, [surveyId, intl]);

  return (
    <div className='d-flex align-items-center'>
      {isRunningCalculations && <span className='mr-2 fal fa-spinner fa-spin'></span>}
      <ConfirmAction
        title={intl.formatMessage({
          id: 'smoove.page.project.general.data-actions.calculation.title',
          defaultMessage: 'Perform calculations'
        })}
        size={'md'}
        body={
          <Row>
            <Col>
              {intl.formatMessage({
                id: `smoove.page.project.general.data-actions.calculation.text`,
                defaultMessage:
                  'Applies the calculations configured under `Data` to all cases already imported into the database. The calculation is asynchronous and can take several minutes, depending on the number and complexity.'
              })}
            </Col>
          </Row>
        }
        callback={recalculateHandling}
        btnColor={'primary'}
        btnText={intl.formatMessage({
          id: `smoove.page.project.general.data-actions.calculation.confirm`,
          defaultMessage: 'perform'
        })}
      >
        {openModal => (
          <Button color={'secondary'} className={'mr-2'} onClick={openModal} disabled={isRunningCalculations}>
            {intl.formatMessage({
              id: 'smoove.page.project.general.data-actions.calculation.button',
              defaultMessage: 'Perform calculations'
            })}
          </Button>
        )}
      </ConfirmAction>
    </div>
  );
};
