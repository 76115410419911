import { isEqual } from 'lodash';
import { useMemo } from 'react';

/**
 * Returns the memorized row order with applied manual sortation.
 *
 * @param {array} order The current order array from tableResult.order
 * @param {object} row The table row configuration from table.rows
 * @param {boolean} showHiddenRows Flag if hidden rows should be included or not
 *
 * @returns {object} The updated row order and row span {rowOrder: [], rowSpan: x}
 */
export const useRowOrder = (order = [], row = {}, showHiddenRows = false) => {
  return useMemo(() => {
    return getRowOrder(order, row, showHiddenRows);
  }, [order, row, showHiddenRows]);
};

/**
 * Returns the memorized row order with applied manual sortation.
 *
 * @param {array} order The current order array from tableResult.order
 * @param {object} row The table row configuration from table.rows
 * @param {boolean} showHiddenRows Flag if hidden rows should be included or not
 *
 * @returns {object} The updated row order and row span {rowOrder: [], rowSpan: x}
 */
export const getRowOrder = (order = [], row = {}, showHiddenRows = false) => {
  const sortation = row?.config?.sortation ?? {};

  let _rowOrder =
    order?.filter(orderItem => {
      if (row?.config && Object.keys(row.config).length > 0) {
        let metric = 'smvrslt_perc';
        let showMetric = true;

        if (
          row.config?.calcMeanValue &&
          (row.config?.calcMeanValue?.id === orderItem?.subelement ||
            row.config?.calcMeanValue?.id === orderItem?.subsubelement)
        ) {
          metric = 'smvrslt_mean';
        }

        showMetric = (metric === 'smvrslt_perc' && row.config?.calcFrequencies !== false) || metric === 'smvrslt_mean';

        if (!showMetric) return false;
      }

      return orderItem.rowid === row.id;
    }) ?? [];

  /** check for manual row sortation or default order */
  if (sortation?.sortationMode === 'manual' && !!sortation?.manualSortation?.order) {
    const _rowOrderElementsSorted = [];
    sortation.manualSortation.order.forEach(subelement => {
      _rowOrderElementsSorted.push(..._rowOrder.filter(order => order.subelement === subelement));
    });

    const _rowOrderElementsNotSorted = [..._rowOrder].filter(
      order => !_rowOrderElementsSorted.find(_order => isEqual(order, _order))
    );

    _rowOrder = [..._rowOrderElementsSorted, ..._rowOrderElementsNotSorted];
  }

  /** calculate rowspan for detailed matrix questions */
  const _rowSpan = _rowOrder.filter(_order => {
    const isHiddenScale = _order?.hidden ?? false;
    const isExcludedScale = _order?.excluded ?? false;
    if (!showHiddenRows && (isHiddenScale || isExcludedScale)) return false;
    else return true;
  }).length;

  return { rowOrder: _rowOrder, rowSpan: _rowSpan };
};
