import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Input, UncontrolledTooltip } from 'reactstrap';

/**
 * @param {*} element Varname parent element. It can be a question|calculation|etc.
 * @param {boolean} readOnly True if varname is not writable, otherwise false.
 * @param {boolean} allowEmpty True if varname is allowed to be empty, otherwise false.
 * @param {Function} handler Reference to function which updates varname.
 */
export const VarnameInputWithValidation = ({ element, readOnly, allowEmpty, handler }) => {
  const intl = useIntl();
  const initVarname = element.varname ?? element.config?.varname ?? '';
  const [varnameInput, setVarnameInput] = useState(initVarname);
  const [isVarnameValid, setisVarnameValid] = useState(true);

  const handleSetVarnameInput = value => {
    setVarnameInput(value);

    if (allowEmpty && !value) {
      setisVarnameValid(true);
      return;
    }

    if (value.toLowerCase().substring(0, 4) === 'sys_') {
      setisVarnameValid(false);
      return;
    }

    const isValidVarname = /^[a-zA-Z]{1}[\w@#.$]{0,52}[a-zA-Z0-9@#$]{1}$/.test(value);

    if (!isValidVarname) {
      setisVarnameValid(false);
    } else {
      setisVarnameValid(true);
    }
    return;
  };

  const saveVarname = value => {
    if (!readOnly && value !== initVarname && isVarnameValid) {
      handler
        .updateVarname(value)
        .then(() => {
          setVarnameInput(value);
          setisVarnameValid(true);
        })
        .catch(err => {
          setVarnameInput(initVarname);
        });
    } else {
      setVarnameInput(initVarname);
      setisVarnameValid(true);
    }
  };

  return (
    <div>
      <Input
        id={`varname_${element.id}_input`}
        type='text'
        value={varnameInput || ''}
        placeholder={intl.formatMessage({
          id: 'smoove.common.varname.enter-variable-name',
          defaultMessage: 'Enter variable name...'
        })}
        onChange={e => handleSetVarnameInput(e.target.value)}
        disabled={readOnly}
        onBlur={() => saveVarname(varnameInput)}
      />
      <UncontrolledTooltip
        placement='right'
        target={`varname_${element.id}_input`}
        fade={true}
        delay={500}
        style={{ textAlign: 'left', marginLeft: '5px', minWidth: '300px' }}
      >
        {intl.formatMessage({
          id: 'smoove.common.varname.varname-naming-rules',
          defaultMessage: 'Enter variable name...'
        })}
        <ul>
          <li>
            {intl.formatMessage({
              id: 'smoove.common.varname.must-start-with-one-letter',
              defaultMessage: 'must start with at least one letter'
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: 'smoove.common.varname.reserved-words',
              defaultMessage: 'are not allowed to start with "sys_"'
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: 'smoove.common.varname.must-end-with-one-char',
              defaultMessage: 'must end with a: letter, digit, @, # or $'
            })}
          </li>
          <li>
            {intl.formatMessage(
              {
                id: 'smoove.common.varname.total-chars',
                defaultMessage: 'is allowed to contain {minChars}-54 characters'
              },
              {
                minChars: allowEmpty ? 0 : 2
              }
            )}
          </li>{' '}
          <li>
            {intl.formatMessage({
              id: 'smoove.common.varname.allowed-chars',
              defaultMessage: 'allowed to contain: latin letters (upper|lower-case), digits, underscores, @, #, . or $"'
            })}
          </li>
          <li>
            {intl.formatMessage({
              id: 'smoove.common.varname.not-allowed-chars',
              defaultMessage: 'NOT allowed: hyphen or spaces'
            })}
          </li>
        </ul>
      </UncontrolledTooltip>
      {!isVarnameValid && (
        <small className='text-warning'>
          {intl.formatMessage({ id: 'smoove.common.varname.invalid-varname', defaultMessage: 'invalid varname' })}
        </small>
      )}
    </div>
  );
};
