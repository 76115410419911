import React, { useCallback, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { isArray } from 'lodash';
import produce from 'immer';
import classNames from 'classnames';

import {
  SingleVectorSettings,
  MultipleVectorSettings,
  SingleMatrixSettings,
  MultipleMatrixSettings
} from './question-types';

import './RowQuestionSettings.scss';
import { SingleInputNumberSettings } from './question-types/SingleInputNumberSettings';

export const RowQuestionSettingsButton = ({ toggle }) => {
  return (
    <div className='smv-custom-dropdown__buttons' onClick={toggle}>
      <i
        className={classNames({
          'fal fa-cog': true,
          'icon-smv-blue mr-1': true
        })}
      ></i>
      <span>{`settings`}</span>
    </div>
  );
};

export const RowQuestionSettingsModal = props => {
  const { isOpen, toggle, row, rowid, handler } = props;
  const [internalRowConfig, setInternalRowConfig] = useState(isArray(row.config) ? {} : row.config ? row.config : {});

  const saveHandler = useCallback(
    internalRowConfig => {
      handler.setTableConfig(state =>
        produce(state, draft => {
          draft.rows.list[rowid].config = internalRowConfig;
        })
      );
      toggle();
    },
    [handler, toggle, rowid]
  );

  const saveOnSubmit = useCallback(
    event => {
      event.preventDefault();
      saveHandler(internalRowConfig);
    },
    [saveHandler, internalRowConfig]
  );

  const internalHandler = useMemo(() => {
    return {
      setInternalRowConfig: setInternalRowConfig,
      setCheckboxValue: e => {
        const { name, checked } = e.target;
        setInternalRowConfig(state =>
          produce(state, draftState => {
            draftState[name] = checked;
          })
        );
      }
    };
  }, []);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size={'xl'} centered>
      <Form onSubmit={saveOnSubmit}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage
            id={'smoove.page.survey.tables.modals.question-settings.headline'}
            defaultMessage={'Edit Question Settings'}
          />
        </ModalHeader>
        <ModalBody>
          <QuestionTypeSettingsSwitch
            setInternalRowConfig={setInternalRowConfig}
            internalRowConfig={internalRowConfig}
            internalHandler={internalHandler}
            {...props}
          />
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={() => saveHandler(internalRowConfig)}>
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const QuestionTypeSettingsSwitch = props => {
  const { sourceType } = props.row;
  const type = useMemo(() => {
    if (sourceType === 'question') {
      return props.element?.config?.qtype;
    } else if (sourceType === 'sysvar') {
      return props.element.varType;
    } else if (sourceType === 'calcvar') {
      return props.element?.multiple ? 'multiple' : 'single';
    }
  }, [props.element, sourceType]);

  const shelfResultType =
    props.element.config?.shelfResultType ?? props.element.config?.shelf?.config?.shelfResultType ?? null;

  if (
    ['single_vector'].includes(type) ||
    (type === 'custom_shelf' && shelfResultType === 'single') ||
    (sourceType === 'calcvar' && type === 'single') ||
    (sourceType === 'sysvar' && type === 'single')
  )
    return <SingleVectorSettings {...props} />;
  else if (
    ['multiple_vector'].includes(type) ||
    (type === 'custom_shelf' && shelfResultType === 'multiple') ||
    (sourceType === 'calcvar' && type === 'multiple') ||
    (sourceType === 'sysvar' && type === 'multiple')
  )
    return <MultipleVectorSettings {...props} />;
  else if (['single_matrix'].includes(type)) return <SingleMatrixSettings {...props} />;
  else if (['multiple_matrix'].includes(type)) return <MultipleMatrixSettings {...props} />;
  else if (['single_input_number'].includes(type)) return <SingleInputNumberSettings {...props} />;
  else return <MissingQuestionTypeSettings />;
};

const MissingQuestionTypeSettings = () => {
  return (
    <Row>
      <Col className={'d-flex flex-column'}>
        <h6>General Settings</h6>
        <span>The current question does not have specific setting options.</span>
      </Col>
    </Row>
  );
};
