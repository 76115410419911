import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { stripHtml } from 'smv-helpers';
import { getMergedChoices } from 'src/smoove/components/misc/helper';
import { useDefaultMetric } from './helper';
import {
  DefaultQuestionMenu,
  MultipleVectorChoiceMenu,
  MultipleVectorQuestionMenu,
  SingleVectorChoiceMenu
} from './menus';
import { QuestionSelectionDetails, ResultRow, ResultRowHeader } from '.';

export const MatrixDetailResultRow = ({ element, table, tableResult, row, questionHas, handler, hover }) => {
  const list = useSelector(state => state.survey.questionnaire.list);

  const { heads, config } = table;
  const { showCodeValuesRow = false, showHiddenRows = true } = config;
  const { id, config: rowConfig } = row;
  const { sortation } = rowConfig;

  const rowOrder = useMemo(() => tableResult?.order?.filter(row => row.rowid === id) ?? [], [tableResult?.order, id]);

  const defaultMetric = useDefaultMetric(table);

  const calcFrequencyGroups = row?.config?.calcFrequencyGroups ?? {};
  let subelements = element?.config?.choices ?? {};
  let _head = heads;

  if (!!subelements?.import?.source) {
    subelements = getMergedChoices(element, element.config.choices, list);
  }

  const subelementsOrder = useMemo(() => {
    let _subelementsOrder = subelements?.order ?? [];
    /** check for manual row sortation or default order */
    if (sortation?.sortationMode === 'manual' && !!sortation?.manualSortation?.order) {
      const _subelementOrderElementsNotSorted = [..._subelementsOrder].filter(
        subelementid => !sortation.manualSortation.order.includes(subelementid)
      );

      _subelementsOrder = [...sortation.manualSortation.order, ..._subelementOrderElementsNotSorted];
    }

    return _subelementsOrder;
  }, [subelements?.order, sortation]);

  const rowHeadLabel = stripHtml(element.title);

  /** Calculate Rowspans */
  const { totalSpan, rowSpans } = useMemo(() => {
    let totalSpan = 0,
      rowSpans = {};

    subelementsOrder.forEach(choiceid => {
      let isHiddenChoice = rowConfig?.hidden?.[choiceid] === true;

      const visibleChoiceOrderItems = rowOrder.filter(order => {
        let metric = defaultMetric;
        let showMetric = true;

        if (Object.keys(rowConfig).length > 0) {
          if (rowConfig?.calcMeanValue && rowConfig?.calcMeanValue?.id === order.subsubelement) metric = 'smvrslt_mean';

          showMetric =
            (metric === 'smvrslt_perc' && rowConfig?.calcFrequencies !== false) ||
            (metric === 'smvrslt_mean' && rowConfig?.calcMeanValue?.id === order.subsubelement);
        }

        return (
          order.subelement === choiceid &&
          showMetric &&
          (showHiddenRows || (order?.hidden !== true && order?.excluded !== true))
        );
      });
      if (visibleChoiceOrderItems.length === 0) isHiddenChoice = true;

      if (isHiddenChoice && !showHiddenRows) {
        rowSpans[choiceid] = 0;
      } else {
        totalSpan += visibleChoiceOrderItems.length;
        rowSpans[choiceid] = visibleChoiceOrderItems.length;
      }
    });

    return { totalSpan, rowSpans };
  }, [rowOrder, subelementsOrder, rowConfig, showHiddenRows, defaultMetric]);

  const _rows = [];

  subelementsOrder.forEach(choiceid => {
    let isHiddenChoice = rowConfig?.hidden?.[choiceid] === true;
    const visibleChoiceOrderItems = rowOrder.filter(
      order => order.subelement === choiceid && (showHiddenRows || (order?.hidden !== true && order?.excluded !== true))
    );
    if (visibleChoiceOrderItems.length === 0) isHiddenChoice = true;

    if (isHiddenChoice && !showHiddenRows) return null;

    const _choiceRows = [];

    visibleChoiceOrderItems.forEach(order => {
      const isHiddenScale = order?.hidden ?? false;
      const isExcludedScale = order?.excluded ?? false;

      if (!showHiddenRows && (isHiddenScale || isExcludedScale)) return null;

      const sortationPinned = rowConfig?.sortation?.pinnedElements?.[order.subsubelement] ?? null;
      let metric = defaultMetric;

      if (Object.keys(rowConfig).length > 0) {
        // if (order.value === 'mean') metric = 'smvrslt_mean';
        if (rowConfig?.calcMeanValue && rowConfig?.calcMeanValue?.id === order.subsubelement) metric = 'smvrslt_mean';

        if (metric === 'smvrslt_perc' && rowConfig?.calcFrequencies === false) return null;
      }

      let questionMenu = null;
      let choiceMenu = null;

      if (element.config.qtype === 'single_matrix') {
        questionMenu = (
          <DefaultQuestionMenu
            rowid={id}
            row={row}
            element={element}
            subelements={subelements}
            handler={handler}
            questionHas={questionHas}
            table={table}
          />
        );

        if (metric !== 'smvrslt_mean') {
          choiceMenu = (
            <SingleVectorChoiceMenu
              handler={handler}
              table={table}
              rowid={id}
              choiceid={order.subelement}
              scaleid={order?.subsubelement ?? null}
              isHidden={isHiddenScale || isHiddenChoice}
              isExcluded={isExcludedScale}
              sortationPinned={sortationPinned}
              subelements={element?.config?.scales}
            />
          );
        }
      } else if (element.config.qtype === 'multiple_matrix') {
        questionMenu = (
          <MultipleVectorQuestionMenu
            rowid={id}
            row={row}
            element={element}
            subelements={subelements}
            handler={handler}
            questionHas={questionHas}
            table={table}
          />
        );

        if (metric !== 'smvrslt_mean') {
          choiceMenu = (
            <MultipleVectorChoiceMenu
              handler={handler}
              table={table}
              rowid={id}
              choiceid={order.subelement}
              scaleid={order?.subsubelement ?? null}
              isHidden={isHiddenScale || isHiddenChoice}
              isExcluded={isExcludedScale}
              sortationPinned={sortationPinned}
              subelements={element?.config?.scales}
            />
          );
        }
      }

      let subelement = {};
      if (calcFrequencyGroups?.[order?.subsubelement]) {
        subelement = calcFrequencyGroups[order.subsubelement];
      } else if (element?.config?.scales?.list?.[order?.subsubelement]) {
        subelement = element.config.scales.list[order.subsubelement];
      }

      _choiceRows.push(
        <ResultRow
          key={`result-row-${order.rowid}_${order.subelement}_${order.subsubelement}`}
          rowid={id}
          orderItem={order}
          subelement={subelement}
          choiceid={choiceid}
          scaleid={order.subsubelement}
          config={config}
          heads={_head}
          tableResult={tableResult}
          handler={handler}
          metric={metric}
          matrixType={'detail'}
          isHidden={isHiddenScale || isHiddenChoice}
          isExcluded={isExcludedScale}
          sortationPinned={sortationPinned}
          isCompactMatrix={false}
          isDetailedMatrix={true}
          menu={choiceMenu}
          hover={hover}
        >
          <>
            {_rows.length === 0 && _choiceRows.length === 0 && (
              <ResultRowHeader
                rowid={id}
                row={row}
                handler={handler}
                element={element}
                label={rowHeadLabel}
                rowSpan={totalSpan}
                questionHas={questionHas}
                menu={questionMenu}
                questionSelectionDetails={
                  <QuestionSelectionDetails row={row} questionHas={questionHas} element={element} />
                }
              />
            )}
            {_choiceRows.length === 0 && (
              <ResultRowHeader
                rowid={id}
                row={row}
                orderItem={{ value: subelements?.list?.[choiceid]?.value }}
                subelement={subelements?.list?.[choiceid]}
                rowSpan={rowSpans[choiceid]}
                handler={handler}
                element={element}
                hasMenu={false}
                isHidden={isHiddenChoice}
                isExcluded={isExcludedScale}
                showCodeValuesRow={showCodeValuesRow}
                toggleHidden={() => handler.toggleSubelementVisibility('rows', 'hidden', id, order.subelement)}
              />
            )}
          </>
        </ResultRow>
      );
    });

    _rows.push(..._choiceRows);
  });

  return _rows;
};
