import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Col, Input, Label, FormGroup, Row, Button, ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { forOwn, isArray, isEqual } from 'lodash';
import produce from 'immer';
import { useSelector } from 'react-redux';

import { reportsActions } from 'src/smoove/redux/actions';
import { isNumeric, useGetSourceElement } from 'smv-helpers';
import { useResultTableType } from 'smv-helpers';

import { GeneralChartConfig } from '../../project-results/ChartConfig';
import { ChartAxisConfig } from '../../project-results/ChartConfig';
import { MediaImageConfig } from './MediaConfig';

import './ConfigDrawerRight.scss';

const INITIAL_STATE = {
  id: null,
  sourceType: null,
  chartConfig: {}
};

const getObjDiff = (obj1, obj2) => {
  const difference = {};

  forOwn(obj1, (value, key) => {
    if (!isEqual(value, obj2[key])) {
      difference[key] = value;
    }
  });

  return difference;
};

export const ConfigDrawerRight = ({ isOpen, report, page, element = null }) => {
  const [internalConfig, setInternalConfig] = useState(INITIAL_STATE);
  const [prevConfig, setPrevConfig] = useState(INITIAL_STATE);

  const [isConfigModified, setIsConfigModified] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const tables = useSelector(state => state.survey.tables.list);

  const isTable = element?.sourceType === 'table' || element?.sourceType !== 'media';
  const { resultTableType } = useResultTableType(tables?.[element?.sourceId] ?? null);

  useEffect(() => {
    // save config on close, if anything has changed
    if (!isOpen && isConfigModified && internalConfig.id !== null && internalConfig.sourceId !== null) {
      if (!isEqual(internalConfig?.chartConfig, prevConfig?.chartConfig)) {
        const diff = getObjDiff(internalConfig.chartConfig, prevConfig.chartConfig);
        reportsActions.updateDashboardElementChartConfig(report.id, page.id, internalConfig.id, diff);
      }
      if (!isEqual(internalConfig?.mediaConfig, prevConfig?.mediaConfig)) {
        const diff = getObjDiff(internalConfig.mediaConfig, prevConfig.mediaConfig);
        reportsActions.updateDashboardElementMediaConfig(report.id, page.id, internalConfig.id, diff);
      }

      setIsConfigModified(false);
    }
  }, [element, page.id, report.id, isOpen, isConfigModified, prevConfig, internalConfig]);

  useEffect(() => {
    setInternalConfig(state => {
      if (!element) return INITIAL_STATE;
      else {
        return produce(state, draft => {
          draft.id = element?.id;
          draft.sourceType = element?.sourceType ?? 'table';
          draft.chartConfig = element?.chartConfig ?? INITIAL_STATE.chartConfig;

          if (draft.sourceType === 'table') {
            draft.sourceId = element?.sourceId;
            draft.splitConfig = element?.splitConfig ?? {};
            draft.filterConfig = element?.filterConfig ?? {};
          } else if (draft.sourceType === 'media') {
            draft.mediaConfig = element?.mediaConfig ?? {};
          }
        });
      }
    });
  }, [element]);

  useEffect(() => {
    if (isOpen && !isLoaded) {
      setIsLoaded(true);
      let currentState = { ...INITIAL_STATE, ...(element ?? {}) };
      currentState.chartConfig = {
        ...(tables?.[element?.sourceId]?.chart ?? {}),
        ...(element?.chartConfig ?? {}),
        ...(internalConfig.id === element.id ? internalConfig.chartConfig : {})
      };
      setPrevConfig(currentState);
    }

    if (!isOpen && isLoaded) {
      setIsLoaded(false);
      setPrevConfig(INITIAL_STATE);
    }
  }, [prevConfig, internalConfig, isLoaded, isOpen, element, tables]);

  const internalHandler = useMemo(
    () => ({
      toggleCheckboxValue: e => {
        const { name, checked } = e.target;
        const [configSection, property] = name.split('.');

        if (!name.includes('.')) {
          console.error('`toggleCheckboxValue`: input name must contain a dot path like `table.showTotal`!');
          return;
        }

        if (!['mediaConfig', 'chartConfig'].includes(configSection)) {
          console.error(`\`toggleCheckboxValue\`: invalid config section '${configSection}'!`);
          return;
        }
        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            draft[configSection][property] = checked;
          })
        );
      },
      setConfigStringValue: e => {
        const { name, value } = e.currentTarget;

        const [configSection, property] = name?.split('.');
        if (!name.includes('.')) {
          console.error('`toggleCheckboxValue`: input name must contain a dot path like `table.showTotal`!');
          return;
        }

        if (!['mediaConfig', 'chartConfig'].includes(configSection)) {
          console.error(`\`toggleCheckboxValue\`: invalid config section '${configSection}'!`);
          return;
        }
        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            draft[configSection][property] = value;
          })
        );
      },
      setChartConfigValue: e => {
        const { name, value } = e.target;
        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = value;
          })
        );
      },
      unsetChartConfigValue: name => {
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] !== undefined) {
              draft.chartConfig[name] = element?.table?.chart?.[name];
            } else {
              delete draft.chartConfig[name];
            }
          })
        );
      },
      toggleSplit: splitid => {
        if (element.splitConfig?.enabledSplits?.[splitid]) {
          reportsActions.removeDashboardEnabledSplit(report.id, page.id, element.id, splitid).then(() => {
            setInternalConfig(state =>
              produce(state, draft => {
                delete draft.splitConfig.enabledSplits[splitid];
              })
            );
            delete element.splitConfig.enabledSplits[splitid];
          });
        } else {
          reportsActions.addDashboardEnabledSplit(report.id, page.id, element.id, splitid).then(() => {
            setInternalConfig(state =>
              produce(state, draft => {
                if (!draft?.splitConfig) draft.splitConfig = { enabledSplits: {} };
                if (!draft.splitConfig?.enabledSplits || isArray(draft.splitConfig.enabledSplits)) {
                  draft.splitConfig.enabledSplits = {};
                }
                draft.splitConfig.enabledSplits[splitid] = true;
              })
            );
            if (!element.splitConfig) element.splitConfig = { enabledSplits: {} };
            if (!element.splitConfig.enabledSplits) element.splitConfig.enabledSplits = {};
            element.splitConfig.enabledSplits[splitid] = true;
          });
        }
      },
      toggleDisabledFilter: filterId => {
        if (element.filterConfig?.disabledFilters?.[filterId]) {
          reportsActions.removeDashboardDisabledFilter(report.id, page.id, element.id, filterId).then(() => {
            setInternalConfig(state =>
              produce(state, draft => {
                delete draft.filterConfig.disabledFilters[filterId];
              })
            );
            delete element.filterConfig.disabledFilters[filterId];
          });
        } else {
          reportsActions.addDashboardDisabledFilter(report.id, page.id, element.id, filterId).then(() => {
            setInternalConfig(state =>
              produce(state, draft => {
                if (!draft?.filterConfig) draft.filterConfig = { disabledFilters: {} };
                if (!draft.filterConfig?.disabledFilters) draft.filterConfig.disabledFilters = {};
                draft.filterConfig.disabledFilters[filterId] = true;
              })
            );
            if (!element.filterConfig) element.filterConfig = { disabledFilters: {} };
            if (!element.filterConfig.disabledFilters) element.filterConfig.disabledFilters = {};
            element.filterConfig.disabledFilters[filterId] = true;
          });
        }
      },
      setColorScheme: e => {
        const { name, value } = e.target;
        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = value;
          })
        );
      },
      setCheckboxValue: e => {
        const { name, checked } = e.target;
        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === checked) delete draft.chartConfig[name];
            else {
              draft.chartConfig[name] = checked;
            }
          })
        );
      },
      setSelectValue: e => {
        const { name, value } = e.target;

        // convert pure number strings to number
        let _value;
        if (!isNaN(+value)) _value = +value;
        else _value = value;

        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = _value;
          })
        );
      },
      setChartDomainRange: e => {
        const { value, name } = e.target;
        setIsConfigModified(true);
        setInternalConfig(state =>
          produce(state, draft => {
            const _value = isNumeric(value) ? parseFloat(value) : null;
            if (element?.table?.chart?.[name] === value) delete draft.chartConfig[name];
            else draft.chartConfig[name] = _value;
          })
        );
      }
    }),
    [element, page.id, report.id]
  );

  if (!element) return null;

  return (
    <div className='reports-config-drawer'>
      <DashboardElementHeaderConfig
        internalConfig={internalConfig}
        internalHandler={internalHandler}
        element={element}
      />

      {isTable && (
        <ChartConfig
          reportId={report?.id}
          pageId={page?.id}
          internalConfig={internalConfig}
          internalHandler={internalHandler}
          tableCharts={tables?.[element?.sourceId]?.chart ?? element?.table?.chart ?? element.chartConfig}
          resultTableType={resultTableType}
        />
      )}

      {isTable && (
        <DynamicSplitSelect
          internalConfig={internalConfig}
          internalHandler={internalHandler}
          report={report}
          element={element}
        />
      )}

      {isTable && (
        <FilterDisableSelect
          internalConfig={internalConfig}
          internalHandler={internalHandler}
          report={report}
          element={element}
        />
      )}

      {element.sourceType === 'media' && (
        <MediaImageConfig internalConfig={internalConfig} internalHandler={internalHandler} element={element} />
      )}
    </div>
  );
};

const DashboardElementHeaderConfig = ({ internalConfig, internalHandler, element }) => {
  const intl = useIntl();
  return (
    <ListGroupItem className='result-config'>
      <ListGroupItemHeading>
        <FormattedMessage id={'smoove.page.tables.chart-config.chart-header'} defaultMessage={'Chart header'} />
      </ListGroupItemHeading>
      <Row className='mt-1 align-items-center'>
        <Label for='chartTitle' md={5}>
          <FormattedMessage id={'smoove.page.tables.chart-config.chart-title'} defaultMessage={'Chart title'} />
        </Label>
        <Col md={7}>
          <Input
            type={'text'}
            id={`chartTitle`}
            name={'title'}
            value={internalConfig?.chartConfig?.title ?? ''}
            placeholder={
              element?.table?.name ?? intl.formatMessage({ id: `smoove.page.tables.chart-config.enter-chart-title` })
            }
            onChange={internalHandler.setChartConfigValue}
          ></Input>
        </Col>
      </Row>

      <Row className='mt-1 align-items-center'>
        <Label for='chartSubtitle' md={5}>
          <FormattedMessage id={'smoove.page.tables.chart-config.chart-subtitle'} defaultMessage={'Chart subtitle'} />
        </Label>
        <Col md={7}>
          <Input
            type={'text'}
            id={`chartSubtitle`}
            name={'subTitle'}
            value={internalConfig?.chartConfig?.subTitle ?? ''}
            placeholder={'enter an optional subtitle...'}
            onChange={internalHandler.setChartConfigValue}
          ></Input>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

const ChartConfig = ({ reportId, pageId, internalConfig, internalHandler, tableCharts, resultTableType }) => {
  // const intl = useIntl();

  const defaultConfig = useMemo(() => {
    return {
      chartType: 'bar',
      chartOrientation: 'horizontal',
      chartSubtype: 'grouped',
      colorSchema: 'viridis',
      colorSchemaReverse: false,
      tableTranspose: false,
      // axis settings
      innerRadius: 0,
      chartDomainAuto: true,
      chartDomainMax: '',
      chartDomainXAuto: true,
      chartDomainXMin: true,
      chartDomainXMax: '',
      chartDomainYAuto: true,
      chartDomainYMin: '',
      chartDomainYMax: '',
      axisYreversed: false,
      axisYwidth: 60,
      dimensionsReversed: false,
      showGrid: false,
      showBase: true,
      showQuestionTitleColumn: false
    };
  }, []);

  const isConfigModified = useMemo(
    () => Object.keys(internalConfig.chartConfig).some(k => Object.keys(defaultConfig).includes(k)),
    [internalConfig, defaultConfig]
  );

  const resetChanges = useCallback(() => {
    Object.keys(defaultConfig).forEach(k => internalHandler.unsetChartConfigValue(k));
    reportsActions.deleteDashboardElementChartConfig(reportId, pageId, internalConfig.id, true);
  }, [internalHandler, internalConfig, defaultConfig, reportId, pageId]);

  const currentChartConfig = useMemo(() => {
    const conf = { ...defaultConfig, ...(tableCharts ?? {}), ...(internalConfig.chartConfig ?? {}) };
    return Object.keys(conf)
      .filter(key => key in defaultConfig)
      .reduce((obj = {}, key) => ({ ...obj, [key]: conf[key] }), {});
  }, [internalConfig, tableCharts, defaultConfig]);

  const generalChartConfigHandler = useMemo(() => {
    return {
      setChartType: internalHandler.setChartConfigValue,
      setChartOrientation: internalHandler.setChartConfigValue,
      setChartSubtype: internalHandler.setChartConfigValue,
      setChartColorSchema: internalHandler.setColorScheme,
      setCheckboxValue: internalHandler.setCheckboxValue
    };
  }, [internalHandler]);

  const axisHandler = useMemo(() => {
    return {
      setChartDomainAuto: internalHandler.setCheckboxValue,
      setChartDomainRange: internalHandler.setChartDomainRange,
      setCheckboxValue: internalHandler.setCheckboxValue,
      setSelectValue: internalHandler.setSelectValue,
      setChartAxisYwidth: internalHandler.setSelectValue
    };
  }, [internalHandler]);

  // Todo: translations!!

  return (
    <>
      <GeneralChartConfig
        chartConfig={currentChartConfig}
        handler={generalChartConfigHandler}
        resultTableType={resultTableType}
      />
      <ChartAxisConfig chartConfig={currentChartConfig} handler={axisHandler} />
      <Row>
        <Col className='d-flex justify-content-end'>
          <Button size={'sm'} color={'secondary'} onClick={resetChanges} disabled={!isConfigModified}>
            <FormattedMessage
              id={'smoove.page.tables.chart-config.reset-to-default'}
              defaultMessage={'Reset to default'}
            />
          </Button>
        </Col>
      </Row>
    </>
  );
};

const DynamicSplitSelect = ({ internalConfig, internalHandler, report, element }) => {
  if (report?.splits?.order?.length <= 0) return null;

  return (
    <ListGroupItem className='result-config mt-4'>
      <ListGroupItemHeading>
        <FormattedMessage
          id={'smoove.page.tables.chart-config.dynamic-splitting'}
          defaultMessage={'Dynamic splitting'}
        />
      </ListGroupItemHeading>
      <div>
        <FormattedMessage
          id={'smoove.page.tables.chart-config.dynamic-splitting-hint'}
          defaultMessage={'Dynamic splitting-hint'}
        />
      </div>
      {report?.splits?.order?.map(splitid => {
        const split = report.splits.list[splitid];
        return (
          <Row key={splitid} className='mt-1 align-items-center'>
            <Col md={5}>{split?.label ?? 'unlabeled split'}</Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`enableSplit_${split.id}`}
                  name={`enableSplit_${split.id}`}
                  checked={
                    internalConfig.splitConfig?.enabledSplits?.[split.id] === true ??
                    element?.splitConfig?.enabledSplits?.[split.id] === true ??
                    false
                  }
                  onChange={() => internalHandler.toggleSplit(split.id)}
                />
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </ListGroupItem>
  );
};

const FilterDisableSelect = ({ internalConfig, internalHandler, report, element }) => {
  const intl = useIntl();
  const getSourceElement = useGetSourceElement();

  if (report?.filters?.order?.length <= 0) return null;

  return (
    <ListGroupItem className='result-config mt-4'>
      <ListGroupItemHeading>
        {intl.formatMessage({
          id: 'smoove.page.reports.dashboard-element-config.filter-disable-select.headline',
          defaultMessage: 'Disable filters'
        })}
      </ListGroupItemHeading>
      <div>
        {intl.formatMessage({
          id: 'smoove.page.reports.dashboard-element-config.filter-disable-select.hint',
          defaultMessage: 'disables selected filters for this element'
        })}
      </div>
      {report?.filters?.order?.map(filterId => {
        const filter = report.filters.list[filterId];
        const { elementTitle } = getSourceElement(filter);
        const label = filter?.label?.length > 0 ? filter.label : elementTitle;

        return (
          <Row key={filterId} className='mt-1 align-items-center'>
            <Col md={5}>{label}</Col>
            <Col md={7}>
              <FormGroup switch>
                <Input
                  type='switch'
                  role='switch'
                  id={`disableFilter_${filter.id}`}
                  name={`disableFilter_${filter.id}`}
                  checked={
                    internalConfig.filterConfig?.disabledFilters?.[filter.id] === true ??
                    element?.filterConfig?.disabledFilters?.[filter.id] === true ??
                    false
                  }
                  onChange={() => internalHandler.toggleDisabledFilter(filter.id)}
                />
              </FormGroup>
            </Col>
          </Row>
        );
      })}
    </ListGroupItem>
  );
};
